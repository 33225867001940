import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AmberBalanceButton from './AmberBalanceButton';
import AmberBalanceNoDropdown from './AmberBalanceNoDropdown';

import { ReactComponent as AmberPinkWave } from '../../../assets/images/AmberPinkWave.svg';
import { ReactComponent as AmberAutomaticBalance } from '../../../assets/images/AmberAutomaticBalance.svg';
import { getUserSelector } from '../../../store/user';
import { RECOIL_LOADABLE_HAS_VALUE } from '../../../store/constants';
import { convertSecondsToObjectHHMMSS } from '../../../helpers/time';
import AmberTitle from '../../Common/Typography/AmberTitle';
import AmberTitleRegular from '../../Common/Typography/AmberTitleRegular';
import AmberMenuItems from '../../Common/Menus/AmberMenuItems';
import AmberPrimaryButton from '../../Common/Buttons/AmberPrimaryButton';
import AmberPrimaryOutlinedButton from '../../Common/Buttons/AmberPrimaryOutlinedButton';

import AmberText from '../../Common/Typography/AmberText';
import { getRequestQuoteLinkByLang } from '../../../helpers/links';
import AmberPrimaryTextLinkBigBold from '../../Common/Buttons/AmberPrimaryTextLinkBigBold';
import { AMBER_PRIMARY } from '../../../material-ui/colors';

function shouldDisplayAddCreditButton(user) {
  // If user is part of an organization
  if (user.organizationId) {
    return false;
  }
  return true;
}

// Balance
export default function UserBalance() {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const userLoadable = useRecoilValueLoadable(getUserSelector);

  // Anchor for drop down menu
  const [anchorElBalance, setAnchorElBalance] = useState(null);

  // Opens the balance menu
  const handleOpenMenuBalance = (event) => {
    setAnchorElBalance(event.currentTarget);
  };

  // Closes the balance menu
  const handleCloseMenuBalance = () => {
    setAnchorElBalance(null);
  };

  // Add balance
  const handleClickAddBalance = () => {
    navigate('/upload?m=nf');
    handleCloseMenuBalance();
  };

  const redirectUserToPlanPage = () => {
    navigate('/plan');
  };

  // Opens the quote tab in the correct language
  const openQuoteTab = () => {
    const { language } = i18n;
    const win = window.open(getRequestQuoteLinkByLang(language), '_blank');
    win.focus();
  };

  function getAddBalanceButtonText(isManualUser, isSubscribed) {
    if (isManualUser) {
      return isSubscribed ? t('V2.PLANBALANCE.viewPlan') : t('V2.NAV.addAutomaticBalance');
    }
    return isSubscribed ? t('V2.PLANBALANCE.viewPlan') : t('V2.NAV.addBalance');
  }

  if (userLoadable.state === RECOIL_LOADABLE_HAS_VALUE) {
    const user = userLoadable.contents.data;

    const balance = convertSecondsToObjectHHMMSS(user.availableSeconds);

    const manualTranscriptionBalance = convertSecondsToObjectHHMMSS(
      user.manualTranscriptionBalance);
    const manualCaptionsBalance = convertSecondsToObjectHHMMSS(
      user.manualCaptionsBalance);
    const manualTsBalance = convertSecondsToObjectHHMMSS(
      user.manualTsBalance);

    const manualTranscriptionBalanceText = `${manualTranscriptionBalance.isNegative ? '-' : ''}${manualTranscriptionBalance.h}h ${manualTranscriptionBalance.m}m ${manualTranscriptionBalance.s}s`;
    const manualCaptionsBalanceText = `${manualCaptionsBalance.isNegative ? '-' : ''}${manualCaptionsBalance.h}h ${manualCaptionsBalance.m}m ${manualCaptionsBalance.s}s`;
    const manualTsBalanceText = `${manualTsBalance.isNegative ? '-' : ''}${manualTsBalance.h}h ${manualTsBalance.m}m ${manualTsBalance.s}s`;

    const showAddCreditButton = shouldDisplayAddCreditButton(user);
    const isManualUser = user.manualBalanceEnabled;
    const isSubscribed = user.userSubscription
      ? user.userSubscription.active
      : false;

    const handleClickRequestMoreMinutes = () => {
      const email = 'info@amberscript.com';
      const userEmail = user.email;
      const subject = encodeURIComponent(`${userEmail} is requesting to top-up their manual balance`);
      const body = encodeURIComponent('Hi Amberscript Team,\n\nI would be interested in purchasing more manual balances for my account. Please get in touch with me!\n\nBest regards');
      const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;
    };

    return (
      <Box display="flex" alignItems="center" height="100%">
        {showAddCreditButton ? (
          <AmberBalanceButton
            testid="dashboard-balance-btn"
            balance={balance}
            handleClick={handleOpenMenuBalance}
          />
        ) : (
          <AmberBalanceNoDropdown
            testid="dashboard-balance-text"
            balance={balance}
          />
        )}
        {showAddCreditButton && (
          <AmberMenuItems
            testid="dashboard-balance-menu"
            anchorEl={anchorElBalance}
            handleCloseMenu={handleCloseMenuBalance}
          >
            {/* Top part (Add balance) */}
            <Box
              marginTop={{ xs: 1.5, sm: 2, md: 3, lg: 4, xl: 5 }}
              marginBottom={{ xs: 3, sm: 4, md: 5, lg: 6, xl: 7 }}
              marginLeft={6}
              marginRight={6}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {isManualUser ? (
                  <AmberText text={t('V2.NAV.automaticBalance')} />
                ) : (
                  <AmberText text={t('V2.NAV.yourBalance')} />
                )}
                <AmberTitle
                  text={`${balance.isNegative ? '-' : ''}${balance.h}h ${balance.m}m ${balance.s}s`}
                />
              </Box>
              <Box marginTop={{ xs: 2, sm: 3, xl: 5 }}>
                <AmberPrimaryButton
                  id={isSubscribed ? '' : 'footer-btn-add-credit'}
                  testid="dashboard-addBalance-btn"
                  text={getAddBalanceButtonText(isManualUser, isSubscribed)}
                  handleClick={
                    isSubscribed
                      ? redirectUserToPlanPage
                      : handleClickAddBalance
                  }
                  fullWidth
                />
              </Box>
            </Box>
            <Divider />

            {/* Middle part (Manual balances) */}
            {isManualUser && (
              <Box
                display="flex"
                flexDirection="column"
                marginTop={{ xs: 2, sm: 3, xl: 5 }}
                marginBottom={{ xs: 1.5, sm: 2, md: 3, lg: 4, xl: 5 }}
                marginLeft={6}
                marginRight={6}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  marginTop={{ xs: 2, sm: 3, xl: 5 }}
                >
                  <div style={{ marginRight: '8px' }}>
                    <AmberTitleRegular text={t('V2.NAV.manualBalances')} />
                  </div>
                  <AmberAutomaticBalance style={{ fill: AMBER_PRIMARY, width: '30px' }} />
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <AmberText text={t('V2.NAV.perfectTranscription')} marginRight={2} />
                  <AmberTitle
                    text={manualTranscriptionBalanceText}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <AmberText text={t('V2.NAV.perfectCaptions')} />
                  <AmberTitle
                    text={manualCaptionsBalanceText}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <AmberText text={t('V2.NAV.perfectTranslatedSubtitles')} />
                  <AmberTitle
                    text={manualTsBalanceText}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <AmberPrimaryOutlinedButton
                    text={t('V2.NAV.requestBalance')}
                    handleClick={handleClickRequestMoreMinutes}
                    fullWidth
                  />
                </Box>
              </Box>
            )}
            {isManualUser && <Divider />}
            {/* Bottom part (Request a quote) */}
            <Box
              display="flex"
              flexDirection="column"
              marginTop={{ xs: 2, sm: 3, md: 4, lg: 5 }}
              marginBottom={{ xs: 1.5, sm: 2, md: 3, lg: 4, xl: 5 }}
              marginLeft={6}
              marginRight={6}
            >
              <AmberText text={t('V2.NAV.largeProject')} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop={{ xs: 2, sm: 3, xl: 5 }}
              >
                <AmberPinkWave />
                <AmberPrimaryTextLinkBigBold
                  testid="dashboard-quote-link"
                  text={t('V2.NAV.requestQuote')}
                  handleClick={openQuoteTab}
                />
              </Box>
            </Box>
          </AmberMenuItems>
        )}
      </Box>
    );
  }

  return null;
}
