// ******** API CALLS - AXIOS ******** \\
export const AMBER_ACCESS_TOKEN_EXP_TIME = 'accessTokenExpirationTime';
export const AMBER_REFRESH_TOKEN_EXP_TIME = 'refreshTokenExpirationTime';

// ******** NOTIFICATIONS ******** \\
export const AMBER_NOTIFICATION_SUCCESS = 'success';
export const AMBER_NOTIFICATION_ERROR = 'error';
export const AMBER_NOTIFICATION_INFO = 'info';
export const AMBER_NOTIFICATION_WARN = 'warn';

// ******** EDITOR ******** \\
export const EDITOR_IFRAME_ID = 'iframe-editor';

// ******** USER RECORD STATUS ******** \\
export const USER_RECORD_OPEN = 'OPEN';
export const USER_RECORD_BUSY = 'BUSY';
export const USER_RECORD_DONE = 'DONE';
export const USER_RECORD_IN_PROGRESS = 'IN_PROGRESS';
export const USER_RECORD_PERFECT_DONE = 'PERFECT_DONE';
export const USER_RECORD_PERFECT_REQUESTED = 'PERFECT_REQUESTED';
export const USER_RECORD_PERFECT_PAYMENT_PENDING = 'PERFECT_PAYMENT_PENDING';
export const USER_RECORD_PAYMENT_PENDING = 'PAYMENT_PENDING';
export const USER_RECORD_ERROR = 'ERROR';

export const USER_RECORD_JOBTYPE_DIRECT = 'direct';
export const USER_RECORD_JOBTYPE_AUTOMATIC = 'Automatic';

export const USER_RECORD_JOBTYPE_PERFECT = 'perfect';
export const USER_RECORD_JOBTYPE_MANUAL = 'Manual';

export const TRANSCRIPTION_TYPE_CAPTIONS = 'captions';
export const TRANSCRIPTION_TYPE_TRANSCRIPTION = 'transcription';
export const TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES = 'translatedSubtitles';

// ******** ADMIN USER STATUS ******** \\
export const ADMIN_USER_ACTIVE = 'ACTIVE';
export const ADMIN_USER_PAUSED = 'PAUSED';

// ******** REGISTER USER SIGNUP VOLUME ******** \\
export const USER_SIGNUP_VOLUME_SMALL = '109';
export const USER_SIGNUP_VOLUME_MEDIUM = '110';
export const USER_SIGNUP_VOLUME_LARGE = '106';
export const USER_SIGNUP_VOLUME_GIANT = '107';
export const USER_SIGNUP_VOLUME_EXTRA_GIANT = '108';

// ******** REGISTER USER SIGNUP PRODUCT IDS ******** \\
export const TRANSLATED_SUBTITLE_ID = '48';
export const AUTOMATIC_TRANSCRIPTION_ID = '50';
export const MANUAL_TRANSCRIPTION_ID = '51';
export const AUTOMATIC_SUBTITLE_ID = '52';
export const MANUAL_SUBTITLES_ID = '53';

// ******** REGISTER USER SIGNUP PRODUCT ******** \\
// manual_subtitles should be ploral to match definition in BE
export const USER_SIGNUP_PRODUCT_MANUAL_SUBTITLE = 'manual_subtitles';
export const USER_SIGNUP_PRODUCT_AUTO_SUBTITLE = 'automatic_subtitle';
export const USER_SIGNUP_PRODUCT_TRANSLATED_SUBTITLE = 'translated_subtitle';
export const USER_SIGNUP_PRODUCT_MANUAL_TRANSCRIPTION = 'manual_transcription';
export const USER_SIGNUP_PRODUCT_AUTO_TRANSCRIPTION = 'automatic_transcription';

// ******** GA4 STORAGE ******** \\
export const ANALYTICS_CLIENT_ID = 'client_id';
export const ANALYTICS_ID = 'user_id';

// ******** GA4 CATEGORIES ******** \\
export const GA_CAT_DASHBOARD = 'dashboard';
export const GA_CAT_ONBOARDING = 'onboarding';
export const GA_CAT_CHECKOUT = 'checkout';

// ******** GA4 EVENTS ******** \\
export const GA_EVENT_OPEN_FILE = 'open_file';
export const GA_EVENT_SKIP_QUESTION = 'skip_question';
export const GA_EVENT_OPEN_DEMO = 'open_demo';
export const GA_EVENT_SUBMIT_QUESTION = 'submit_question';
export const GA_EVENT_REQUEST_UPGRADE = 'request_upgrade';
export const GA_EVENT_QUOTE_LINK = 'tap_quote_link';
export const GA_EVENT_REQUEST_INTEGRATION = 'request_integration';
export const GA_EVENT_UPLOAD_FILE = 'upload_file';
export const GA_EVENT_EXPORT_FILE = 'export_file';
export const GA_EVENT_PLACE_ORDER = 'place_order';
export const GA_EVENT_LOGIN = 'login';
export const GA_EVENT_PURCHASE = 'purchase';
export const GA_EVENT_PURCHASE_PERFECT = 'Perfect';
export const GA_EVENT_PURCHASE_PREPAID = 'Prepaid';
export const GA_EVENT_PURCHASE_SUBSCRIPTION = 'Subscription';
export const GA_EVENT_OPEN_DICTIONARY = 'open_dictionary';
export const GA_EVENT_UPDATE_DICTIONARY = 'update_dictionary';
export const GA_EVENT_CREATE_DICTIONARY = 'create_dictionary';
export const GA_EVENT_NAVIGATE_UPLOAD_PAGE = 'navigate_upload_page';
export const GA_EVENT_NAVIGATE_CHECKOUT_PAGE = 'navigate_checkout_page';
export const GA_EVENT_UPLOAD_BEFORE_SIGNUP = 'upload_before_signup';
export const GA_EVENT_POST_TO_YOUTUBE = 'post_youtube';

// ******** GA TAP_QUOTE_LINK ADDITIONAL PARAMS ******** \\
export const GA_AUTOMATIC = 'automatic';
export const GA_MANUAL = 'manual';
export const GA_TRANSLATED_SUBTITLES = 'translated_subtitles';
export const GA_TRANSLATED_TRANSCRIPTION = 'transcription';
export const GA_TRANSLATED_CAPTIONS = 'captions';
export const GA_TRANSLATED_JUST_SUBTITLES = 'subtitles';

// ******** GLOSSARY DIALOG MODES ******** \\
export const GLOSSARY_MODE_CREATE = 'GLOSSARY_MODE_CREATE';
export const GLOSSARY_MODE_ALL = 'GLOSSARY_MODE_ALL';
export const GLOSSARY_MODE_EDIT = 'GLOSSARY_MODE_EDIT';
export const GLOSSARY_MODE_DELETE = 'GLOSSARY_MODE_DELETE';

// ******** ADD GLOSSARY SELECT OPTION ******** \\
export const GLOSSARY_SELECT_ADD = 'GLOSSARY_SELECT_ADD';

// ******** GLOSSARY FIELD LENGTHS & ARRAY SIZES ******** \\
export const GLOSSARY_MAX_CHAR = 60;
export const GLOSSARY_MAX_NAMES = 15;
export const GLOSSARY_MIN_TERMS = 5;
export const GLOSSARY_MAX_TERMS = 20;
export const GLOSSARY_MAX_TERM_DEF = 200;

// ******** GLOSSARY COLOR ******** \\
export const GLOSSARY_BORDER_COLOR = '#c4c4c4';

// ******** USER ROLES ******** \\
export const USER_ROLE_REAL = 'REAL';
export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_ORG_ADMIN = 'ORG_ADMIN';
export const USER_ROLE_ORG_TEAM_ADMIN = 'ORG_TEAM_ADMIN';
export const USER_ROLE_ORG_TEAM_MEMBER = 'ORG_TEAM_MEMBER';
export const USER_ROLE_TRANSCRIBER = 'TRANSCRIBER';
export const USER_ROLE_PERFECT_JOB_MANAGER = 'PERFECT_JOB_MANAGER';
export const USER_ROLE_QUALITY_CONTROLLER = 'QUALITY_CONTROLLER';

// ******** GA ORDER OPTIONS ******** \\
export const GA_OPTION_VERBATIM = 'verbatim';
export const GA_OPTION_RUSH = 'rush';

// ******** PASSWORD ******** \\
export const PASSWORD_MIN_LENGTH = 8;

// ******** UPLOAD BEFORE SIGNUP ******** \\
export const NON_USER_TOKEN = 'nonUserToken';
export const NON_USER_ID = 'nonUserId';
export const FRONT_END = 'frontend';

// ******** GOOGLE SSO ******** \\
export const GOOGLE_SSO_MAX_TIME_NEW_USER = 10;

// ******** REGISTER STEP LOGIN/REGISTER PAGE TO ONBOARDING ******** \\
export const LOGIN_REGISTER_STEP = 0;
export const ONBOARDING_STEP = 1;

// ******** DOMAINS ******** \\
export const TRANSKRIPTO_DOMAIN = 'transkripto';
export const UITGETYPT_DOMAIN = 'uitgetypt';
export const ABTIPPER_DOMAIN = 'abtipper';
