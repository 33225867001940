import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

import Header from '../Header';
import FirebaseMessaging from '../../FirebaseMessaging';
import ReferralDialog from '../ReferralDialog';
import { isBrowserSafari } from '../../../helpers/userAgent';

export default function DashboardBase(props) {
  const { children, maxScreenHeight, testid, withPadding } = props;
  const theme = useTheme();

  const getPadding = () => {
    if (withPadding) {
      return { xs: 4, sm: 6, md: 7, lg: 7, xl: 8 };
    }
    return 0;
  };

  const getPaddingTop = () => {
    if (withPadding) {
      return 6;
    }
    return 0;
  };

  const isFirebaseSupported = () =>
    process.env.REACT_APP_ENVIRONMENT !== 'test' && !isBrowserSafari();

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={theme.palette.table.headerBackground}
      minHeight="100vh"
      maxHeight={maxScreenHeight ? '100vh' : 'unset'}
      overflow={maxScreenHeight ? 'hidden' : 'unset'}
      data-testid={testid}
    >
      <Header />
      {isFirebaseSupported() && <FirebaseMessaging />}

      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        paddingBottom={getPadding()}
        paddingLeft={getPadding()}
        paddingRight={getPadding()}
        paddingTop={getPaddingTop()}
        overflow={maxScreenHeight ? 'hidden' : 'unset'}
      >
        {children}
      </Box>
      <ReferralDialog />
    </Box>
  );
}

DashboardBase.propTypes = {
  maxScreenHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  testid: PropTypes.string,
  withPadding: PropTypes.bool,
};

DashboardBase.defaultProps = {
  testid: '',
  withPadding: true,
  maxScreenHeight: false,
};
