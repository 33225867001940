import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import AmberRootButton from './AmberRootButton';

const CustomButton = styled(LoadingButton)(() => ({
  textTransform: 'none',
}));

export default function AmberLoadingButton(props) {
  const {
    text,
    variant,
    disabled,
    loading,
    icon,
    fullWidth,
    handleClick,
    testid,
  } = props;

  return (
    <CustomButton
      data-testid={testid}
      variant={variant}
      color="primary"
      disabled={disabled}
      loading={loading}
      startIcon={icon || undefined}
      fullWidth={fullWidth}
      onClick={handleClick}
      disableElevation
    >
      <AmberRootButton>
        <Typography variant="button">{text}</Typography>
      </AmberRootButton>
    </CustomButton>
  );
}

AmberLoadingButton.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.element,
  fullWidth: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

AmberLoadingButton.defaultProps = {
  variant: 'contained',
  disabled: false,
  loading: false,
  icon: null,
  fullWidth: false,
  testid: '',
};
