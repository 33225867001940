import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberText(props) {
  const {
    text,
    textAlign,
    noWrap,
    color,
    testid,
    marginTop,
    marginBottom,
    lineHeight,
    variant,
    typographyProps,
    ...rest
  } = props;

  return (
    <Box
      data-testid={testid}
      component="span"
      marginTop={marginTop}
      marginBottom={marginBottom}
      textAlign={textAlign}
      whiteSpace={noWrap ? 'nowrap' : 'normal'}
      color={color || 'unset'}
      lineHeight={lineHeight}
      {...rest}
    >
      <Typography variant={variant}>{text}</Typography>
    </Box>
  );
}

AmberText.propTypes = {
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  color: PropTypes.string,
  noWrap: PropTypes.bool,
  testid: PropTypes.string,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  lineHeight: PropTypes.string,
  variant: PropTypes.string,
  typographyProps: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  ),
};

AmberText.defaultProps = {
  textAlign: 'left',
  noWrap: false,
  color: 'unset',
  testid: '',
  marginTop: 1,
  marginBottom: 1,
  lineHeight: '1.5rem',
  variant: 'normal',
  typographyProps: {},
};
