import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AmberRootButton from './AmberRootButton';

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderColor: theme.palette.primary.main,
  minWidth: 80,
  whiteSpace: 'nowrap',
}));

export default function AmberPrimaryOutlinedIconButton(props) {
  const {
    text,
    disabled = false,
    handleClick,
    fullWidth = false,
    testid = '',
    icon,
  } = props;

  return (
    <CustomButton
      variant="outlined"
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
      data-testid={testid}
      startIcon={icon}
    >
      <AmberRootButton>
        <Typography variant="button">{text}</Typography>
      </AmberRootButton>
    </CustomButton>
  );
}

AmberPrimaryOutlinedIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.element,
};

AmberPrimaryOutlinedIconButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  testid: '',
  icon: null,
};
