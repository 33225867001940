/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  glossaryAtom,
  glossaryDialogAtom,
} from '@amberscript/amberscript-fe-components/dist/components/Glossary/state/glossaryState';
import { makeStyles } from '@mui/styles';

import { GLOSSARY_MODE_ALL } from '../../../helpers/constants';
import AmberPrimaryOutlinedIconButton from '../../Common/Buttons/AmberPrimaryOutlinedIconButton';
import { ReactComponent as Dictionary } from '../../../assets/images/ui/dictionary.svg';
import { fireDictionaryOpenEvent } from '../../../helpers/googleAnalytics';
import { getUserSelector } from '../../../store/user';
import { RECOIL_LOADABLE_HAS_VALUE } from '../../../store/constants';
import { getGlossariesSelector } from '../../../store/glossary';
import { parseDomainColour } from '../../../helpers/parseDomain';

const useStyles = makeStyles(() => ({
  svg: {
    '&  path': {
      fill: parseDomainColour(),
    },
  },
}));

export default function DictionaryButton() {
  const [t] = useTranslation();
  const [glossaryDialog, setGlossaryDialog] =
    useRecoilState(glossaryDialogAtom);
  const [glossaryData, setGlossaryData] = useRecoilState(glossaryAtom);
  const userLoadable = useRecoilValueLoadable(getUserSelector);
  const userGlossaries = useRecoilValueLoadable(getGlossariesSelector);
  const refresh = useRecoilRefresher_UNSTABLE(getGlossariesSelector);
  const classes = useStyles();

  // Get all existing glossaries for this user
  const openGlossariesDialog = async () => {
    refresh();
    setGlossaryDialog({
      ...glossaryDialog,
      open: true,
      mode: GLOSSARY_MODE_ALL,
    });
    if (userLoadable.state === RECOIL_LOADABLE_HAS_VALUE) {
      const user = userLoadable.contents.data;
      const timeElapsed = Date.now();
      const dateTime = new Date(timeElapsed);
      fireDictionaryOpenEvent({
        userId: user.analyticsId,
        time: dateTime.toUTCString(),
      });
    }
  };

  useEffect(() => {
    const glossaries = userGlossaries.contents.data;
    if (userGlossaries.state === RECOIL_LOADABLE_HAS_VALUE) {
      setGlossaryData({
        ...glossaryData,
        selectedGlossary: glossaries[0],
        glossaries: [...glossaries],
      });
    }
  }, [userGlossaries.contents.data]);

  return (
    <Box>
      <AmberPrimaryOutlinedIconButton
        text={t('V2.GLOSSARY.dictionary')}
        handleClick={openGlossariesDialog}
        icon={<Dictionary className={classes.svg} />}
        testid="open-glossaries-dialog"
      />
    </Box>
  );
}
