import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

export default function AmberTextField(props) {
  const {
    helperText = '',
    disabled = false,
    handleChange,
    handleBlur,
    value,
    label,
    error = false,
    testid = '',
    id = '',
    isRequired = false,
    type = 'text',
  } = props;

  return (
    <TextField
      data-testid={testid}
      inputProps={{ 'data-testid': `${testid}-textField` }}
      autoComplete="off"
      label={label}
      variant="outlined"
      helperText={helperText}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={label}
      value={value}
      error={error}
      id={id}
      fullWidth
      required={isRequired}
      type={type}
    />
  );
}

AmberTextField.propTypes = {
  helperText: PropTypes.string,
  testid: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  handleBlur: PropTypes.func,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
};

AmberTextField.defaultProps = {
  helperText: '',
  testid: '',
  disabled: false,
  error: false,
  id: '',
  handleBlur: () => {},
  isRequired: false,
  type: 'text',
};
