import React from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  closeButton: {
    cursor: 'pointer',
  },
  noMinHeight: {
    minHeight: 'unset!important',
  },
});

export default function HeaderNotification(props) {
  const { contentNode, isOpen, handleCloseClick } = props;
  const classes = useStyles();

  return (
    <Collapse in={isOpen} className={classes.noMinHeight}>
      <Box
        px={6}
        py={2}
        bgcolor="custom.sunflowerYellow"
        display="flex"
        justifyContent="space-between"
        data-testid="header-notification"
        minHeight="48px"
      >
        <Box display="flex" alignItems="center">
          {contentNode}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          onClick={handleCloseClick}
          className={classes.closeButton}
          data-testid="notification-close-button"
        >
          <CloseIcon />
        </Box>
      </Box>
    </Collapse>
  );
}

HeaderNotification.propTypes = {
  contentNode: PropTypes.node,
  isOpen: PropTypes.shape({
    default: PropTypes.shape({}),
  }),
  handleCloseClick: PropTypes.func,
};

HeaderNotification.defaultProps = {
  contentNode: <></>,
  isOpen: false,
  handleCloseClick: () => {},
};
