import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilValueLoadable } from 'recoil';
import AmberSelectNoBorder from '../../Common/Inputs/AmberSelectNoBorder';
import getAvailableLanguages from '../../../helpers/languages';
import { updateUser } from '../../../api/user';
import { getUserSelector } from '../../../store/user';
import useNotification from '../../../helpers/customHooks/notifications';
import { AMBER_NOTIFICATION_ERROR } from '../../../helpers/constants';

export default function LanguageSelectorWithUserUpdate() {
  const [t, i18n] = useTranslation();
  const userLoadable = useRecoilValueLoadable(getUserSelector);
  const user = userLoadable.contents.data;
  const [showNotification] = useNotification();

  const updateUserLang = async (language) => {
    const userData = { language, userName: user.userName };
    try {
      await updateUser(userData);
    } catch (error) {
      showNotification(AMBER_NOTIFICATION_ERROR, t('UPLOAD_FORM.plan.error'));
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    updateUserLang(value);
  };

  const OPTIONS = getAvailableLanguages(t);

  return (
    <AmberSelectNoBorder
      value={i18n.language}
      options={OPTIONS}
      onChange={handleChange}
    />
  );
}
