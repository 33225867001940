import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberTitleHeader(props) {
  const { text } = props;

  return (
    <Box component="span">
      <Typography variant="header">{text}</Typography>
    </Box>
  );
}

AmberTitleHeader.propTypes = {
  text: PropTypes.string.isRequired,
};
