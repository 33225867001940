import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AmberRootButton from './AmberRootButton';

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderColor: theme.palette.primary.main,
  minWidth: 80,
}));

export default function AmberPrimaryOutlinedButton(props) {
  const {
    text,
    disabled = false,
    handleClick,
    fullWidth = false,
    testid = '',
    padding = '5px 15px',
    iconComponent,
  } = props;

  return (
    <CustomButton
      variant="outlined"
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
      data-testid={testid}
      style={{ border: '2px solid', padding }}
    >
      {iconComponent}
      <AmberRootButton>
        <Typography variant="button">{text}</Typography>
      </AmberRootButton>
    </CustomButton>
  );
}

AmberPrimaryOutlinedButton.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  padding: PropTypes.string,
  iconComponent: PropTypes.element,
};

AmberPrimaryOutlinedButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  testid: '',
  padding: '5px 15px',
  iconComponent: null,
};
