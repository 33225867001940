import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AmberText from '../../Common/Typography/AmberText';
import AmberTitle from '../../Common/Typography/AmberTitle';
import theme from '../../../material-ui/theme';

export default function AmberBalanceNoDropdown(props) {
  const { balance } = props;

  const [t] = useTranslation();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box display={{ xs: 'none', sm: 'flex' }} marginLeft={2} marginRight={2}>
        <AmberText
          text={t('V2.NAV.balance')}
          color={theme.palette.custom.charcoalGray}
        />
      </Box>
      <Box marginLeft={{ xs: 2, sm: 0 }} marginRight={{ xs: 0, sm: 2 }}>
        <AmberTitle text={`${balance.h}h ${balance.m}m ${balance.s}s`} />
      </Box>
    </Box>
  );
}

AmberBalanceNoDropdown.propTypes = {
  balance: PropTypes.shape({
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    s: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isNegative: PropTypes.bool.isRequired,
  }).isRequired,
};
