import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { RECOIL_LOADABLE_HAS_VALUE } from '../../../../store/constants';
import { getUserSelector } from '../../../../store/user';
import EmailNotification from './EmailNotification';

export default function NotificationUserLoad() {
  const userLoadable = useRecoilValueLoadable(getUserSelector);

  if (userLoadable.state === RECOIL_LOADABLE_HAS_VALUE) {
    const user = userLoadable.contents.data;

    return <EmailNotification user={user} />;
  }
  return null;
}
