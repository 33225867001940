import { atom, selector } from 'recoil';
import i18next from 'i18next';

import {
  RECOIL_LOADABLE_HAS_VALUE,
  USER_DATA,
  USER_DATA_ATOM,
  USER_SUBSCRIBED,
  USER_TRANSCRIBER,
} from './constants';
import { ANALYTICS_ID, ANALYTICS_CLIENT_ID } from '../helpers/constants';
import { getUser } from '../api/user';
import {
  getAnalyticsClientID,
  getAnalyticsID,
  storeItemInLocalStorage,
} from '../helpers/storage';
import { initDataLayer } from '../helpers/GTM';
import { googleAnalyticsSetUserProperties } from '../helpers/googleAnalytics';

function setGAUserDataStorage(data) {
  const { analytics, analyticsId } = data;

  if (analytics && analytics.googleAnalyticsClientId) {
    storeItemInLocalStorage(
      ANALYTICS_CLIENT_ID,
      analytics.googleAnalyticsClientId,
    );
  }
  if (analyticsId) {
    storeItemInLocalStorage(ANALYTICS_ID, analyticsId);
  }

  googleAnalyticsSetUserProperties({
    client_id: getAnalyticsClientID(),
    user_id: getAnalyticsID(),
  });
}

function initGa(user) {
  initDataLayer();
  setGAUserDataStorage(user);
}

// Set current language to preferred language from user data
function setUserPreferredLanguage(response) {
  if (response && response.data) {
    const { language } = response.data;
    i18next.changeLanguage(language);
  }
}

// ATOMS
export const forceUserUpdate = atom({
  key: USER_DATA_ATOM,
  default: 0,
});

export const subscribedAtom = atom({
  key: USER_SUBSCRIBED,
  default: false,
});

export const transcriberAtom = atom({
  key: USER_TRANSCRIBER,
  default: false,
});

// SELECTORS
export const getUserSelector = selector({
  key: USER_DATA,
  get: async ({ get }) => {
    const forceUpdateCounter = get(forceUserUpdate);
    const response = await getUser();

    if (forceUpdateCounter === 0) {
      setUserPreferredLanguage(response);
      initGa(response.data);
    }

    return response;
  },
});

/**
 * Returns the user object if loaded
 * Null otherwise
 * @param {Object} userLoadable
 * @returns {Object} user
 */
export function getUserFromUserLoadable(userLoadable) {
  return userLoadable.state === RECOIL_LOADABLE_HAS_VALUE
    ? userLoadable.contents.data
    : null;
}
