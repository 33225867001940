import React from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import UserBalance from './UserBalance';
import AdminMenu from './AdminMenu';
import UserMenu from './UserMenu';

import AmberPaperOutlinedSticky from '../../Common/Papers/AmberPaperOutlinedSticky';
import AmberLogo from '../../Common/Logo/AmberLogo';
import NotificationUserLoad from './notifications/NotificationUserLoad';
import DictionaryButton from './DictionaryButton';

// Header
export default function Headers() {
  const isDictionaryButtonDisplayed = useLocation().pathname === '/records';

  return (
    <>
      {/* Yellow banner */}
      <NotificationUserLoad />

      {/* Maintenance banner */}
      {/* <MaintenanceNotification /> */}

      {/* Header */}
      <AmberPaperOutlinedSticky>
        <Box
          position="sticky"
          top={0}
          display="flex"
          alignItems="center"
          height={{ xs: 50, sm: 60, md: 70 }}
          marginLeft={{ xs: 4, sm: 5, md: 6, lg: 7 }}
          marginRight={{ xs: 1, sm: 1, md: 2, lg: 3 }}
        >
          {/* Left */}
          <Box
            height="100%"
            maxWidth={{ xs: 120, sm: 140, md: 160, lg: 160, xl: 200 }}
          >
            <AmberLogo />
          </Box>

          {/* Right */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex={2}
            gap={5}
            height="100%"
          >
            {isDictionaryButtonDisplayed && <DictionaryButton />}
            {/* Subscription Badge or Balance */}
            <UserBalance />

            {/* Admin */}
            <AdminMenu />

            {/* Account */}
            <UserMenu />
          </Box>
        </Box>
      </AmberPaperOutlinedSticky>
    </>
  );
}
