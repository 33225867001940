import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import AmberText from '../../Common/Typography/AmberText';
import { AMBER_PRIMARY } from '../../../material-ui/colors';
import AmberCircularLoading from '../../Common/ProgressBars/AmberCircularLoading';

export default function ReferralInviteList(props) {
  const { userReferralListAccepted, userReferralListPending, isLoading } =
    props;
  const [t] = useTranslation();

  const shouldShowEmptyState = () =>
    userReferralListPending.length === 0 &&
    userReferralListAccepted.length === 0;

  return (
    <Box
      p={3}
      mt={4}
      border={2}
      borderColor="custom.lightGray"
      borderRadius={1}
      maxHeight={192}
      height={192}
      overflow="scroll"
    >
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <AmberCircularLoading />
        </Box>
      )}

      {shouldShowEmptyState() && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <AmberText
            text={t('REFERRALDIALOG.noInvites')}
            color="custom.infoMessage"
          />
        </Box>
      )}

      {userReferralListPending &&
        !isLoading &&
        userReferralListPending.map((emailItem) => (
          <Box key={emailItem} justifyContent="space-between" display="flex">
            <AmberText text={emailItem} />
            <AmberText
              text={t('REFERRALDIALOG.invited')}
              color="custom.infoMessage"
            />
          </Box>
        ))}

      {userReferralListAccepted &&
        !isLoading &&
        userReferralListAccepted.map((emailItem) => (
          <Box key={emailItem} justifyContent="space-between" display="flex">
            <AmberText text={emailItem} />
            <AmberText
              text={t('REFERRALDIALOG.referralCompleted')}
              color={AMBER_PRIMARY}
            />
          </Box>
        ))}
    </Box>
  );
}

ReferralInviteList.propTypes = {
  userReferralListAccepted: PropTypes.arrayOf(PropTypes.string).isRequired,
  userReferralListPending: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
