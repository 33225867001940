import { atom, selector } from 'recoil';
import { getGlossaries } from '../api/glossary';
import {
  GLOSSARY_CHECKBOX,
  GLOSSARY_CHECKOUT_ERROR,
  GLOSSARY_CURRENT_BATCH_DETAIL,
  GLOSSARY_GET_ALL,
} from './constants';

export const glossaryCheckoutErrorAtom = atom({
  key: GLOSSARY_CHECKOUT_ERROR,
  default: false,
});

export const glossaryCheckboxAtom = atom({
  key: GLOSSARY_CHECKBOX,
  default: false,
});

export const currentBatchGlossaryIdAtom = atom({
  key: GLOSSARY_CURRENT_BATCH_DETAIL,
  default: null,
});

// SELECTORS
export const getGlossariesSelector = selector({
  key: GLOSSARY_GET_ALL,
  get: async () => {
    const response = await getGlossaries();

    return response;
  },
});
