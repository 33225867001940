import * as Sentry from '@sentry/browser';

const SECTION = 'section';

// Function(s)
export function getUserInformationForSentry() {
  //   if (user) {
  //     const { email, userName, countryCode, userType } = user;
  //     return {
  //       email,
  //       userName,
  //       countryCode,
  //       userType,
  //     };
  //   }
  return { email: 'User not found' };
}

export function logSharingTokensWithEditor(error) {
  Sentry.captureException(error, (scope) => {
    // Set User
    scope.setUser(getUserInformationForSentry());

    // Scope tags
    scope.setTag(SECTION, 'editor_credentials');

    return scope;
  });
}
/**
 * Send the error to Sentry logs passing a tag
 * @param {error} error
 * @param {string} tag
 */
export function trackSentryErrorByTag(error, tag) {
  Sentry.captureException(error, (scope) => {
    // Scope tags
    scope.setTag(SECTION, tag);

    return scope;
  });
}
