import { atom } from 'recoil';
import { FCM_MESSAGE_PAYLOAD, FCM_OPEN_DIALOG, FCM_TOKEN } from './constants';

// Firebase Cloud Messaging atoms

export const firebaseCloudMessagingTokenAtom = atom({
  key: FCM_TOKEN,
  default: null,
});

export const openFeatureDialogAtom = atom({
  key: FCM_OPEN_DIALOG,
  default: false,
});

export const firebaseCloudMessageAtom = atom({
  key: FCM_MESSAGE_PAYLOAD,
  default: {
    image: '',
    title: '',
    text: '',
    btnLabel: '',
    btnLink: '',
  },
});
