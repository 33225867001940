/* eslint-disable import/prefer-default-export */

import { atom } from 'recoil';
import { EMAIL_NOTIFICATION_OPEN } from './constants';

export const emailNotificationAtom = atom({
  key: EMAIL_NOTIFICATION_OPEN,
  default: {
    open: false,
  },
});
