// REDUCER
export const SET_UPLOAD_BATCH_ID = 'SET_UPLOAD_BATCH_ID';
export const SET_ASR_MODELS = 'SET_ASR_MODELS';
export const SET_SYSTEM_FREE = 'SET_SYSTEM_FREE';
export const SET_SYSTEM_BUSY = 'SET_SYSTEM_BUSY';
export const SET_STATES_TO_DEFAULT = 'SET_STATES_TO_DEFAULT';
export const UPDATE_LOADING_BAR = 'UPDATE_LOADING_BAR';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const CHANGE_UPLOAD_STEP = 'CHANGE_UPLOAD_STEP';
export const SET_USER_RECORD_RTP = 'SET_USER_RECORD_RTP';
export const SET_FORM_VALIDATION = 'SET_FORM_VALIDATION';
export const SET_COST_CENTER = 'SET_COST_CENTER';
export const SET_NON_USER_LANGUAGE = 'SET_NON_USER_LANGUAGE';
export const SET_NON_USER_COUNTRY_CODE = 'SET_NON_USER_COUNTRY_CODE';

// STATUS
export const UPLOAD_SYSTEM_FREE = 'UPLOAD_SYSTEM_FREE';
export const UPLOAD_SYSTEM_BUSY = 'UPLOAD_SYSTEM_BUSY';

// STEPS
export const UPLOAD_STEP_ADD = 'UPLOAD_STEP_ADD';
export const UPLOAD_STEP_SELECT = 'UPLOAD_STEP_SELECT';
export const UPLOAD_STEP_PAYMENT = 'UPLOAD_STEP_PAYMENT';
export const UPLOAD_STEP_SIGNUP = 'UPLOAD_STEP_SIGNUP';

// NOTIFCATION
export const NOTIFICATION_SUCCESS = 'success';
export const NOTIFICATION_ERROR = 'error';

// LOCAL STORAGE
export const NEVER_SHOW_QUALITY_DIALOG = 'never_show_quality';
export const LAST_UPLOAD_CHOICE = 'last_upload_choice';
