import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import AmberLogoBlack from '../../../assets/images/AmberLogoBlack.svg';
import { parseDomainLogo } from '../../../helpers/parseDomain';

export default function AmberLogo(props) {
  const { isAnonymous } = props;

  const navigate = useNavigate();

  const redirectToPage = () => {
    // If Upload before signup and not authenticated yet
    if (isAnonymous) {
      return;
    }
    navigate('/records');
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      height="100%"
      sx={{ cursor: 'pointer' }}
      maxWidth={{ xs: 50, sm: 140, md: 180, lg: 200, xl: 240 }}
      onClick={redirectToPage}
      data-testid="unauthenticatedHeader-logo"
    >
      <Box display={{ xs: 'none', sm: 'flex' }}>
        <img
          src={parseDomainLogo()}
          alt="logo"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box
        display={{ xs: 'flex', sm: 'none' }}
        height="60%"
        justifyContent="flex-start"
      >
        <img
          src={AmberLogoBlack}
          alt="logo-mobile"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  );
}

AmberLogo.propTypes = {
  isAnonymous: PropTypes.bool,
};

AmberLogo.defaultProps = {
  isAnonymous: false,
};
