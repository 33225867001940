import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';

const CustomButton = styled(Button)(() => ({
  textTransform: 'none',
  padding: 0,
}));

export default function AmberDarkGreenTextButton(props) {
  const { text, disabled = false, handleClick, testid = '' } = props;

  return (
    <CustomButton
      disabled={disabled}
      onClick={handleClick}
      data-testid={testid}
      disableElevation
    >
      <Box component="span" color="custom.darkGreenColor">
        <Typography variant="button">{text}</Typography>
      </Box>
    </CustomButton>
  );
}

AmberDarkGreenTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

AmberDarkGreenTextButton.defaultProps = {
  disabled: false,
  testid: '',
};
