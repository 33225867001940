import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AmberText from '../Typography/AmberText';
import AmberListButton from '../Buttons/AmberListButton';

const useStyles = makeStyles({
  hideMenuItem: {
    display: 'none',
  },
});

export default function AmberMenuListItemMaker(props) {
  const { listItems, handleCloseMenuUser } = props;
  const classes = useStyles();

  const navigate = useNavigate();
  const [t] = useTranslation();

  // Navigate to path
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleClick = (item) => {
    if (item.address) {
      handleNavigation(item.address);
    } else if (item.dialog) {
      item.openDialog();
      handleCloseMenuUser();
    }
  };

  return (
    <>
      {listItems.map((item) => (
        <MenuItem
          key={item.id}
          onClick={() => handleClick(item)}
          data-testid={item.testid}
          className={item.shouldHide ? classes.hideMenuItem : ''}
          display="none"
        >
          <Box width="100%" paddingTop={2} paddingBottom={2}>
            <AmberText text={t(item.title)} />
          </Box>
          {item.hasAdditionalButton && (
            <AmberListButton
              text={t(item.buttonTitle)}
              testid={item.buttonTestId}
            />
          )}
        </MenuItem>
      ))}
    </>
  );
}

AmberMenuListItemMaker.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      address: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleCloseMenuUser: PropTypes.func,
};

AmberMenuListItemMaker.defaultProps = {
  handleCloseMenuUser: () => {},
};
