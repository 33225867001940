import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberTitleRegular(props) {
  const { text, color = 'unset', testid } = props;

  return (
    <Box component="span" data-testid={testid}>
      <Typography variant="headerNormal" color={color}>
        {text}
      </Typography>
    </Box>
  );
}

AmberTitleRegular.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
  color: PropTypes.string,
};

AmberTitleRegular.defaultProps = {
  color: 'unset',
  testid: '',
};
