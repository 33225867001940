import ReactGA from 'react-ga4';
import {
  SERVICE_AUTOMATIC,
  SERVICE_MANUAL,
  SERVICE_SUBTITLES,
  SERVICE_TRANSCRIPTION,
  TURNAROUND_TIME_ONE_DAY,
  TURNAROUND_TIME_THREE_DAYS,
} from '../components/User/Uploads/newUploadForm/states/constants/services';
import { RECOIL_LOADABLE_HAS_VALUE } from '../store/constants';
import {
  GA_AUTOMATIC,
  GA_EVENT_CREATE_DICTIONARY,
  GA_EVENT_EXPORT_FILE,
  GA_EVENT_LOGIN,
  GA_EVENT_NAVIGATE_CHECKOUT_PAGE,
  GA_EVENT_NAVIGATE_UPLOAD_PAGE,
  GA_EVENT_OPEN_DICTIONARY,
  GA_EVENT_POST_TO_YOUTUBE,
  GA_EVENT_PURCHASE,
  GA_EVENT_UPDATE_DICTIONARY,
  GA_EVENT_UPLOAD_BEFORE_SIGNUP,
  GA_EVENT_UPLOAD_FILE,
  GA_MANUAL,
  GA_OPTION_RUSH,
  GA_OPTION_VERBATIM,
  GA_TRANSLATED_CAPTIONS,
  GA_TRANSLATED_JUST_SUBTITLES,
  GA_TRANSLATED_SUBTITLES,
  GA_TRANSLATED_TRANSCRIPTION,
} from './constants';
import { getCookie } from './cookie';

export function googleAnalyticsFireEvent(
  eventType,
  eventCategory,
  additionalParams = {},
) {
  ReactGA.gtag('event', eventType, {
    event_category: eventCategory,
    ...additionalParams,
  });
}

export function googleAnalyticsSetUserProperties(params) {
  ReactGA.gtag('set', {
    ...params,
  });
}

export function fireGAUpdateUserRegister(
  sector,
  interestedIn,
  volume,
  company,
) {
  const products = [];
  if (interestedIn) {
    if (interestedIn.automatic_subtitle === true) {
      products.push('as');
    }
    if (interestedIn.manual_subtitle === true) {
      products.push('ms');
    }
    if (interestedIn.translated_subtitle === true) {
      products.push('ts');
    }
    if (interestedIn.automatic_transcription === true) {
      products.push('at');
    }
    if (interestedIn.manual_transcription === true) {
      products.push('mt');
    }
  }
  const productsString = products.join(', ');

  googleAnalyticsSetUserProperties({
    sector: sector || '',
    products: productsString || '',
    volume: volume || '',
    company: company || '',
  });
}

export function setupGoogleAnalytics() {
  const clientId = getCookie('_gid');
  if (clientId) {
    googleAnalyticsSetUserProperties({ client_id: clientId });
  }

  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
    debug: process.env.REACT_APP_GA_DEBUG || true,
    gtagOptions: {
      transport_url: process.env.REACT_APP_GA_TRANSPORT_URL,
      first_party_collection: true,
    },
  });
}

function getProductsString(productArray) {
  return productArray.join(', ');
}

function getProductsIDs(productArray) {
  return productArray.map((product) => `${product}_id`).join(', ');
}

function getSelectedProducts(products) {
  if (!products) {
    return null;
  }
  // get all products that's set to true
  const productArray = Object.keys(products).filter((key) => products[key]);
  return {
    productString: getProductsString(productArray),
    productIds: getProductsIDs(productArray),
  };
}

export function pushRegisterEventToDataLayer(
  onboardingData,
  analyticsId,
  language,
  email,
) {
  const dataLayer = window.dataLayer || [];

  const selectedProducts = getSelectedProducts(onboardingData.signupProducts);
  const productString = selectedProducts?.productString;
  const productIds = selectedProducts?.productIds;

  const data = {
    event: 'sign_up',
    name: onboardingData?.firstName,
    user_id: analyticsId,
    email,
    language,
    country_code: onboardingData?.country,
    sector: onboardingData?.sector,
    company: onboardingData?.company,
    volume: onboardingData?.signupVolume,
    volume_id: onboardingData?.signupVolume,
    products: productString,
    product_ids: productIds,
  };

  dataLayer.push(data);
}

/**
 * Pushes an event to the data layer (NO GA4)
 * @param {object} data
 */
export function pushEventToDataLayer(data) {
  const dataLayer = window.dataLayer || [];
  dataLayer.push(data);
}

/**
 * Returns a string constant corresponding to the service type
 * @param {boolean} isAutomatic
 */
export function getServiceType(isAutomatic) {
  return isAutomatic ? GA_AUTOMATIC : GA_MANUAL;
}

/**
 * Returns a string constant corresponding to the service type
 * @param {string} serviceType
 */
export function getServiceTypeByString(serviceType) {
  if (serviceType === SERVICE_AUTOMATIC) {
    return GA_AUTOMATIC;
  }
  if (serviceType === SERVICE_MANUAL) {
    return GA_MANUAL;
  }
  return null;
}

/**
 * Returns a string constant corresponding to the product type
 * @param {boolean} translatedSubtitles
 * @param {boolean} isTranscription
 * @param {boolean} isSubtitle
 */
export function getProductType(
  translatedSubtitles,
  isTranscription,
  isSubtitle,
) {
  if (translatedSubtitles) {
    return GA_TRANSLATED_SUBTITLES;
  }
  if (isTranscription) {
    return GA_TRANSLATED_TRANSCRIPTION;
  }
  if (isSubtitle) {
    return GA_TRANSLATED_CAPTIONS;
  }
  return null;
}

/**
 * Pushes the upload_file event to the data layer (NO GA4)
 * @param {object} user
 * @param {string} formats
 */
export function fireUploadEvent(user, formats) {
  const { fullName, email, countryCode, sectorCode, analyticsId, language } =
    user;
  const { company } = user.billing;

  const data = {
    event: GA_EVENT_UPLOAD_FILE,
    name: fullName?.trim(),
    email,
    language,
    country_code: countryCode,
    sector: sectorCode || '',
    company: company || '',
    user_id: analyticsId,
    upload_format: formats,
  };

  pushEventToDataLayer(data);
}
/**
 * Pushes the export_file event to the data layer (NO GA4)
 * @param {object} user
 * @param {object} transcriptStatus
 * @param {string} format
 * @param {string} type
 * @param {string} product
 */
export function fireExportEvent(user, transcriptStatus, format, type, product) {
  if (!user) {
    return;
  }
  const { fullName, email, countryCode, sectorCode, analyticsId, language } =
    user;
  const { company } = user.billing;
  const { transcriptionType } = transcriptStatus.data;

  const data = {
    event: GA_EVENT_EXPORT_FILE,
    name: fullName?.trim(),
    email,
    language,
    country_code: countryCode,
    sector: sectorCode || '', // Can be undefined in some cases
    company,
    user_id: analyticsId,
    export_format: format?.toLowerCase(),
    export_type: type,
    export_product: product || transcriptionType,
  };

  pushEventToDataLayer(data);
}

/**
 * Pushes the login event to the data layer
 * @param {object} user
 */
export function fireLoginEvent(user) {
  const { fullName, email, language, countryCode, sectorCode, analyticsId } =
    user;
  const { company } = user?.billing;

  const data = {
    event: GA_EVENT_LOGIN,
    name: fullName?.trim(),
    email,
    language,
    country_code: countryCode,
    sector: sectorCode || '', // Can be undefined in some cases
    company,
    user_id: analyticsId,
  };

  pushEventToDataLayer(data);
}

/**
 * Fires the login event
 * @param {boolean} login
 * @param {object} userLoadable
 * @param {function} setLogin
 */
export function triggerLoginEvent(login, userLoadable, setLogin) {
  if (login && userLoadable.state === RECOIL_LOADABLE_HAS_VALUE) {
    const user = userLoadable.contents.data;
    fireLoginEvent(user);
    setLogin(false);
  }
}

/**
 * Returns a string constant corresponding to the product type
 * @param {string} selectedService
 * @param {boolean} translated
 */
export function getSelectedServiceByString(selectedService, translated) {
  if (selectedService === SERVICE_TRANSCRIPTION) {
    return GA_TRANSLATED_TRANSCRIPTION;
  }
  if (selectedService === SERVICE_SUBTITLES) {
    if (translated) {
      return GA_TRANSLATED_JUST_SUBTITLES;
    }
    return GA_TRANSLATED_CAPTIONS;
  }
  return null;
}

function isRushOrder(turnaroundTime) {
  return (
    turnaroundTime === TURNAROUND_TIME_ONE_DAY ||
    turnaroundTime === TURNAROUND_TIME_THREE_DAYS
  );
}

/**
 * Returns an array containing the order options
 * @param {boolean} verbatim
 * @param {string} turnaroundTime
 */
export function getOrderOptions(verbatim, turnaroundTime) {
  const rushOrder = isRushOrder(turnaroundTime);
  const options = [
    ...(verbatim ? [GA_OPTION_VERBATIM] : []),
    ...(rushOrder ? [GA_OPTION_RUSH] : []),
  ];
  return options.join('|');
}

/**
 * Pushes the purchase event to the data layer (NO GA4)
 * @param {object} ecommerceInfo
 */
export function firePurchaseEvent(ecommerceInfo) {
  const data = {
    event: GA_EVENT_PURCHASE,
    ...ecommerceInfo,
  };

  pushEventToDataLayer(data);
}

/**
 * Pushes the dictionary open layer
 * @param {object} dictionaryInfo
 */
export function fireDictionaryOpenEvent(dictionaryInfo) {
  const data = {
    event: GA_EVENT_OPEN_DICTIONARY,
    ...dictionaryInfo,
  };

  pushEventToDataLayer(data);
}

/**
 * Fires the navigate_upload_page event
 * @param {object} userInfo
 */

export function fireNavigateToUploadPageEvent(userInfo) {
  const data = {
    event: GA_EVENT_NAVIGATE_UPLOAD_PAGE,
    ...userInfo,
  };

  pushEventToDataLayer(data);
}

/**
 * Pushes the dictionary update layer
 * @param {object} dictionaryInfo
 */
export function fireDictionaryUpdateEvent(dictionaryInfo) {
  const data = {
    event: GA_EVENT_UPDATE_DICTIONARY,
    ...dictionaryInfo,
  };

  pushEventToDataLayer(data);
}
/*
 * Fires the upload_before_signup event
 * @param {Object} info
 */
export function fireUploadBeforeSignupEvent(info) {
  const data = {
    event: GA_EVENT_UPLOAD_BEFORE_SIGNUP,
    ...info,
  };

  pushEventToDataLayer(data);
}

/**
 * Pushes the dictionary create layer
 * @param {object} dictionaryInfo
 */
export function fireDictionaryCreateEvent(dictionaryInfo) {
  const data = {
    event: GA_EVENT_CREATE_DICTIONARY,
    ...dictionaryInfo,
  };

  pushEventToDataLayer(data);
}

/*
 * Fires the navigate_checkout_page event
 * @param {Object} userInfo
 */
export function fireNavigateToCheckoutPageEvent(userInfo) {
  const data = {
    event: GA_EVENT_NAVIGATE_CHECKOUT_PAGE,
    ...userInfo,
  };

  pushEventToDataLayer(data);
}

/**
 * Pushes post video on youtube event
 * @param {object} videoToYoutubeInfo
 */
export function firePostVideoToYoutubeEvent(videoToYoutubeInfo) {
  const data = {
    event: GA_EVENT_POST_TO_YOUTUBE,
    ...videoToYoutubeInfo,
  };

  pushEventToDataLayer(data);
}
