import { getApps, initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { trackSentryErrorByTag } from '../sentry/common';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
};

const VAPID_KEY = process.env.REACT_APP_FCM_VAPID_KEY;

let broadcast = null;

/**
 * Initialize Firebase Cloud Messaging
 */
export async function setupFirebaseCloudMessaging() {
  const firebaseApps = getApps();
  const isFirebaseInitialized = firebaseApps.length > 0;
  const app = isFirebaseInitialized
    ? firebaseApps[0]
    : initializeApp(firebaseConfig);

  return isSupported().then((result) => {
    if (!result) {
      return Promise.reject();
    }
    const messaging = getMessaging(app);
    getAnalytics(app);

    let token = null;
    // eslint-disable-next-line no-undef
    return Notification.requestPermission()
      .then((res) => {
        if (res === 'granted') {
          return getToken(messaging, { vapidKey: VAPID_KEY });
        }
        return null;
      })
      .then((res) => {
        token = res;
        // eslint-disable-next-line no-undef
        broadcast = new BroadcastChannel(
          'FirebaseCloudMessagingBroadcastChannel',
        );
        return token;
      })
      .catch((err) => {
        trackSentryErrorByTag(err, 'firebase-cloud-messaging-token');
      });
  });
}

/**
 * Listen to Firebase Cloud Messaging for incoming messages
 * @param {string} token
 * @param {function} onReceiveMessage
 * @returns
 */
export function listenForFCMMessage(token, onReceiveMessage) {
  if (!token) {
    return;
  }

  const messaging = getMessaging();
  // Message listener
  onMessage(messaging, (payload) => {
    // Display the payload from the argument above
    if (payload && payload.data) {
      const { data } = payload;
      onReceiveMessage(data);
    }
    return null;
  });

  // Listen to the broadcast channel for messages from service worker
  if (broadcast) {
    broadcast.onmessage = (event) => {
      if (event && event.data) {
        const { data } = event;
        onReceiveMessage(data);
      }
      return null;
    };
  }
}
