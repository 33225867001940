import {
  ABTIPPER_DARK,
  ABTIPPER_PRIMARY,
  AMBER_PRIMARY,
  DARK_GREEN_COLOR,
  TRANSKRIPTO_DARK,
  TRANSKRIPTO_PRIMARY,
  UITGETYPT_DARK,
  UITGETYPT_PRIMARY,
} from '../material-ui/colors';

import AmberscriptLogo from '../assets/images/AmberscriptLogo.svg';
import TranscriptoLogo from '../assets/images/Transkripto.svg';
import AbtipperLogo from '../assets/images/Abtipper.svg';
import UitgetyptLogo from '../assets/images/Uitgetypt.svg';
import {
  ABTIPPER_DOMAIN,
  TRANSKRIPTO_DOMAIN,
  UITGETYPT_DOMAIN,
} from './constants';

export function parseDomainColour() {
  if (window.location.hostname.includes(TRANSKRIPTO_DOMAIN)) {
    return TRANSKRIPTO_PRIMARY;
  }
  if (window.location.hostname.includes(ABTIPPER_DOMAIN)) {
    return ABTIPPER_PRIMARY;
  }
  if (window.location.hostname.includes(UITGETYPT_DOMAIN)) {
    return UITGETYPT_PRIMARY;
  }
  return AMBER_PRIMARY;
}

export function parseDomainDarkColour() {
  if (window.location.hostname.includes(TRANSKRIPTO_DOMAIN)) {
    return TRANSKRIPTO_DARK;
  }
  if (window.location.hostname.includes(ABTIPPER_DOMAIN)) {
    return ABTIPPER_DARK;
  }
  if (window.location.hostname.includes(UITGETYPT_DOMAIN)) {
    return UITGETYPT_DARK;
  }
  return DARK_GREEN_COLOR;
}

export function parseDomainLogo() {
  if (window.location.hostname.includes(TRANSKRIPTO_DOMAIN)) {
    return TranscriptoLogo;
  }
  if (window.location.hostname.includes(ABTIPPER_DOMAIN)) {
    return AbtipperLogo;
  }
  if (window.location.hostname.includes(UITGETYPT_DOMAIN)) {
    return UitgetyptLogo;
  }
  return AmberscriptLogo;
}
