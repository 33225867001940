import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function AmberMenuItems(props) {
  const { anchorEl, children, handleCloseMenu, testid = '' } = props;

  let open = Boolean(anchorEl);

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();

    open = false;
    handleCloseMenu();
  };

  return (
    <StyledMenu
      data-testid={testid}
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </StyledMenu>
  );
}

AmberMenuItems.propTypes = {
  anchorEl: PropTypes.shape(),
  children: PropTypes.node,
  handleCloseMenu: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

AmberMenuItems.defaultProps = {
  anchorEl: null,
  children: null,
  testid: '',
};
