import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AmberFeatureDialog from './Common/Dialogs/AmberFeatureDialog';
import {
  firebaseCloudMessageAtom,
  openFeatureDialogAtom,
} from '../store/firebase';
import {
  listenForFCMMessage,
  setupFirebaseCloudMessaging,
} from '../helpers/firebaseCloudMessaging';
import { trackSentryErrorByTag } from '../sentry/common';

export default function FirebaseMessaging() {
  // Setting up Firebase
  const [openFeatureDialog, setOpenFeatureDialog] = useRecoilState(
    openFeatureDialogAtom,
  );
  const setFCMMessage = useSetRecoilState(firebaseCloudMessageAtom);

  // Set message for the dialog and open it
  const onReceiveMessage = (incomingMessage) => {
    setFCMMessage({
      ...incomingMessage,
    });
    setOpenFeatureDialog(true);
  };

  // Initialize Firebase Cloud Messaging
  async function initFCM() {
    try {
      const token = await setupFirebaseCloudMessaging();
      listenForFCMMessage(token, onReceiveMessage);
    } catch (error) {
      trackSentryErrorByTag(error, 'firebase-cloud-messaging-init');
    }
  }

  const closeFeatureDialog = () => {
    setOpenFeatureDialog(false);
  };

  useEffect(() => {
    initFCM();
  }, []);

  return (
    <AmberFeatureDialog
      open={openFeatureDialog}
      handleClick={() => {}}
      handleClose={closeFeatureDialog}
    />
  );
}
