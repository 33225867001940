import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CustomIconButton = styled(Button)(
  ({ theme }) => `
    width: 100%;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    color: ${theme.palette.primary.main};

    .MuiSvgIcon-root.admin-icon {
        font-size: 2.2rem;
    }

    .MuiSvgIcon-root.arrow-down {
      font-size: 1.5rem;
    }
  `,
);

export default function AmberAdminButton(props) {
  const { label = 'label', handleClick, testid = '' } = props;

  return (
    <CustomIconButton aria-label={label} onClick={handleClick} disableRipple>
      <Box
        data-testid={testid}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ fontSize: '2.2rem' }}
      >
        <AdminPanelSettingsIcon className="admin-icon" />
      </Box>
      <KeyboardArrowDownIcon color="action" className="arrow-down" />
    </CustomIconButton>
  );
}

AmberAdminButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

AmberAdminButton.defaultProps = {
  label: 'label',
  testid: '',
};
