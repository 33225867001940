import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { ReactComponent as CloseIcon } from '../../../assets/images/ui/cancel.svg';
import AmberPrimaryButton from '../Buttons/AmberPrimaryButton';
import AmberPrimaryOutlinedButton from '../Buttons/AmberPrimaryOutlinedButton';
import AmberTitleRegular from '../Typography/AmberTitleRegular';
import AmberText from '../Typography/AmberText';
import { firebaseCloudMessageAtom } from '../../../store/firebase';
import { openTab } from '../../../helpers/url';

const useStyles = makeStyles({
  coverImage: {
    maxHeight: 350,
    height: 'auto',
    objectFit: 'cover',
  },
});

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(7),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function AmberFeatureDialog(props) {
  const { open, handleClose } = props;
  const classes = useStyles();

  const [t] = useTranslation();

  const message = useRecoilValue(firebaseCloudMessageAtom);
  const { image, title, text, btnLabel, btnLink } = message;

  const handleClickAction = () => {
    openTab(btnLink);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Fade}
      keepMounted
      padding={0}
      width={{ sm: 'sm', md: 'md' }}
      data-testid="feature-dialog-component"
    >
      <CustomDialogContent>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            position="relative"
            flex={4}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={image}
              alt={image}
              data-testid="feature-dialog-image"
              className={classes.coverImage}
            />
            <Box
              display="flex"
              position="absolute"
              zIndex={10}
              top={16}
              right={16}
            >
              <IconButton
                onClick={handleClose}
                data-testid="feature-dialog-btn-close"
              >
                <CloseIcon height={24} width={24} />
              </IconButton>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            alignItems="center"
            pt={5}
            pb={2.5}
            flex={1}
          >
            <AmberTitleRegular
              text={title}
              testid="feature-dialog-text-title"
            />
            <AmberText text={text} testid="feature-dialog-text-body" />
          </Box>
        </Box>
      </CustomDialogContent>
      <CustomDialogActions>
        <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
          <AmberPrimaryOutlinedButton
            text={t('V2.ONBOARDING.skip')}
            handleClick={handleClose}
            testid="feature-dialog-btn-skip"
          />
          <AmberPrimaryButton
            text={btnLabel}
            handleClick={handleClickAction}
            testid="feature-dialog-btn-action"
          />
        </Box>
      </CustomDialogActions>
    </Dialog>
  );
}

AmberFeatureDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
