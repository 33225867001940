import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import AmberRootButton from './AmberRootButton';

const CustomButton = styled(Button)(() => ({
  textTransform: 'none',
}));

export default function AmberPrimaryButton(props) {
  const { id, text, disabled, handleClick, fullWidth, testid } = props;

  return (
    <CustomButton
      id={id || undefined}
      data-testid={testid}
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={handleClick}
      fullWidth={fullWidth}
      disableElevation
    >
      <AmberRootButton>
        <Typography variant="button">{text}</Typography>
      </AmberRootButton>
    </CustomButton>
  );
}

AmberPrimaryButton.propTypes = {
  id: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  testid: PropTypes.string,
};

AmberPrimaryButton.defaultProps = {
  id: '',
  disabled: false,
  fullWidth: false,
  testid: '',
};
