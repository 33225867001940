import { initializeApp, getApps } from 'firebase/app';
import {
  getAuth,
  getRedirectResult,
  inMemoryPersistence,
  SAMLAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import apiClient from '../api/apiClient';
import { createSamlSessionCookie } from '../api/saml';
import { pushToDataLayer } from './GTM';
import { trackSentryErrorByTag } from '../sentry/common';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FAUTH_API_KEY,
  authDomain: process.env.REACT_APP_FAUTH_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
};

function doPostLoginProcess() {
  try {
    pushToDataLayer();
  } catch (e) {
    trackSentryErrorByTag(e, 'gtm_session_extraction_failed');
  }
}

export async function setupFirebaseAuthentication() {
  const firebaseApps = getApps();
  const isFirebaseInitialized = firebaseApps.length > 0;
  if (!isFirebaseInitialized) {
    initializeApp(firebaseConfig);
  }
  await getAuth().setPersistence(inMemoryPersistence);
}

export async function signInWithEmailPassword(username, password) {
  const auth = getAuth();

  const userCredential = await signInWithEmailAndPassword(
    auth,
    username,
    password,
  );
  const { user } = userCredential;
  if (user) {
    const idToken = await user.getIdToken();

    const formData = new FormData();
    formData.append('idToken', idToken);

    await apiClient.post('session/login', formData, {
      'Content-Type': 'multipart/form-data',
      withCredentials: true,
    });

    await signOut(auth);
    doPostLoginProcess();
    return null;
  }
  return null;
}

export async function signInWithRedirectToProvider(providerId) {
  const firebaseApps = getApps();
  const isFirebaseInitialized = firebaseApps.length > 0;
  if (!isFirebaseInitialized) {
    initializeApp(firebaseConfig);
  }
  const auth = getAuth();
  const provider = new SAMLAuthProvider(providerId);
  await signInWithRedirect(auth, provider);
}

export async function getProviderRedirectResult(organizationParams, email) {
  try {
    const auth = getAuth();
    const userCredential = await getRedirectResult(auth);
    if (userCredential) {
      const { user } = userCredential;
      if (user) {
        const idToken = await user.getIdToken();
        await createSamlSessionCookie(idToken, organizationParams, email);

        await signOut(auth);
        if (user.email) {
          doPostLoginProcess(user.email);
        }
        return Promise.resolve();
      }
    }
    return Promise.resolve(null);
  } catch (error) {
    return Promise.reject(error);
  }
}
