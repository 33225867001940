import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import AmberText from '../Typography/AmberText';
import theme from '../../../material-ui/theme';

const CustomButton = styled(Button)(() => ({
  textTransform: 'none',
  height: '100%',
  paddingLeft: { xs: 0.6, sm: 0.8, md: 1 },
  paddingRight: { xs: 0.6, sm: 0.8, md: 1 },
}));

export default function AmberUserButton(props) {
  const {
    text,
    disabled = false,
    handleClick,
    fullWidth = false,
    testid = '',
  } = props;

  return (
    <CustomButton
      data-testid={testid}
      variant="text"
      disabled={disabled}
      onClick={handleClick}
      fullWidth={fullWidth}
      disableRipple
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box marginRight={{ xs: 0, sm: 2 }}>
          <Box display={{ xs: 'none', sm: 'flex' }}>
            <AmberText text={text} color={theme.palette.custom.charcoalGray} />
          </Box>
          <Box display={{ xs: 'flex', sm: 'none' }}>
            <Ellipse />
          </Box>
        </Box>

        <KeyboardArrowDownIcon color="action" />
      </Box>
    </CustomButton>
  );
}

AmberUserButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  testid: PropTypes.string,
};

AmberUserButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  testid: '',
};
