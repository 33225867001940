import passwordValidator from 'password-validator';
import { PASSWORD_MIN_LENGTH } from './constants';

export const validateEmail = (email) => {
  const isValid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  return isValid;
};

export const validatePassword = (password) => {
  // eslint-disable-next-line new-cap
  const schema = new passwordValidator();

  schema
    .is()
    .min(12) // Minimum length 12
    .is()
    .not()
    .spaces(); // Must not contain space

  return schema.validate(password);
};

export const passwordHasBlankSpace = (password) =>
  password.endsWith(' ') || password.startsWith(' ');

export const passwordIsRightLength = (password) =>
  password.length >= PASSWORD_MIN_LENGTH;

export const passwordValidate = (password) =>
  !passwordIsRightLength(password) || passwordHasBlankSpace(password);
