import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberPrimaryTextLinkBigBold(props) {
  const { text, handleClick, testid = '' } = props;

  return (
    <Box
      component="span"
      marginTop={1}
      marginBottom={1}
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
      }}
      data-testid={testid}
      whiteSpace="nowrap"
    >
      <Typography variant="bigBold" color="primary">
        {text}
      </Typography>
    </Box>
  );
}

AmberPrimaryTextLinkBigBold.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
};

AmberPrimaryTextLinkBigBold.defaultProps = {
  testid: '',
};
