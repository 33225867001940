import React, { useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getUserSelector } from '../../../store/user';
import { RECOIL_LOADABLE_HAS_VALUE } from '../../../store/constants';
import AmberUserButton from '../../Common/Buttons/AmberUserButton';
import AmberMenuItems from '../../Common/Menus/AmberMenuItems';
import AmberMenuListItemMaker from '../../Common/Menus/AmberMenuListItemMaker';
import AmberText from '../../Common/Typography/AmberText';
import AmberTextError from '../../Common/Typography/AmberTextError';
import LanguageSelectorWithUserUpdate from '../Common/LanguageSelectorWithUserUpdate';
import { logout } from '../../../api/loginRegister';
import useNotification from '../../../helpers/customHooks/notifications';
import { viewRedeemCouponDialogAtom } from '../../../store/redeemCoupon';
import handlePostLogout from '../../../helpers/logout';
import { viewReferralDialogAtom } from '../../../store/referral';
import parseFeatureToggle from '../../../helpers/featureToggles';
import AmberSmallTextLabel from '../../Common/Tables/TextFields/AmberSmallTextLabel';

export default function UserMenu() {
  const userLoadable = useRecoilValueLoadable(getUserSelector);
  const [showNotification] = useNotification();

  const navigate = useNavigate();
  const [t] = useTranslation();
  const setViewRedeemCouponDialog = useSetRecoilState(
    viewRedeemCouponDialogAtom,
  );

  const setViewReferralDialog = useSetRecoilState(viewReferralDialogAtom);

  function redeemCouponDialogOpen() {
    setViewRedeemCouponDialog({
      open: true,
    });
  }

  function referralDialogOpen() {
    setViewReferralDialog({
      open: true,
    });
  }

  // Anchors for drop down menu
  const [anchorElUser, setAnchorElUser] = useState(null);

  // Opens the admin menu
  const handleOpenMenuUser = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Closes the admin menu
  const handleCloseMenuUser = () => {
    setAnchorElUser(null);
  };

  // Navigate to path
  const handleNavigate = (path) => {
    navigate(path);
  };

  function moveUserToLoginPage() {
    window.location.replace('/login');
  }

  // Logout
  const handleLogout = async () => {
    try {
      await logout();
      handlePostLogout();
      moveUserToLoginPage();
    } catch (error) {
      showNotification('error', 'FAILED!');
    }
  };

  const REFERRALS_ENABLED = parseFeatureToggle(
    process.env.REACT_APP_FF_REFERRALS,
  );

  function getListUserItems(user) {
    // List of User menu items
    const listUserItems = [
      {
        id: 1,
        address: '/records',
        title: 'NAV.titleRecords',
        testid: 'dashboard-user-item-records',
      },
      {
        id: 2,
        address: '/account',
        title: 'NAV.titleAccount',
        testid: 'dashboard-user-item-account',
      },
      {
        id: 3,
        address: '/billing',
        title: 'NAV.titleBilling',
        testid: 'dashboard-user-item-billing',
      },
      {
        id: 4,
        dialog: true,
        openDialog: redeemCouponDialogOpen,
        title: 'NAV.titleCoupon',
        testid: 'dashboard-user-coupon-coupon',
      },
      {
        id: 5,
        address: '/invoices',
        title: 'NAV.titleInvoices',
        testid: 'dashboard-user-item-invoices',
      },
      {
        id: 6,
        dialog: true,
        openDialog: referralDialogOpen,
        title: 'NAV.titleReferral',
        testid: 'dashboard-user-referral',
        shouldHide: !REFERRALS_ENABLED || !user.activeReferralCode,
        hasAdditionalButton: true,
        buttonTitle: 'NAV.referFriendsButton',
        buttonTestId: 'dashboard-referral-bonus',
      },
      {
        id: 7,
        address: '/integrations',
        title: 'NAV.titleIntegrations',
        testid: 'dashboard-user-item-integrations',
      },
    ];

    const isSubscribed = user.userSubscription && user.userSubscription.active;
    const isAppSumoUser = user.appsumo;

    if (isSubscribed) {
      listUserItems.push({
        id: 6,
        address: '/plan',
        title: 'NAV.titlePlan',
        testid: 'dashboard-user-item-plan',
      });
    }
    if (isAppSumoUser) {
      listUserItems.push({
        id: 7,
        address: '/appsumoplan',
        title: 'NAV.titlePlan',
        testid: 'dashboard-user-item-appsumoplan',
      });
    }
    return listUserItems;
  }

  if (userLoadable.state === RECOIL_LOADABLE_HAS_VALUE) {
    const user = userLoadable.contents.data;

    // Check if user is part of an organization
    const isOrgMember = user.organizationId !== undefined;

    const organizationItem = (
      <MenuItem
        divider
        onClick={() => {
          handleNavigate('/organization');
        }}
      >
        <Box width="100%" paddingTop={2} paddingBottom={2}>
          <AmberText
            testid="dashboard-user-organization"
            text={t('NAV.titleManageOrganization')}
          />
        </Box>
      </MenuItem>
    );

    return (
      <Box display="flex" alignItems="center" height="100%">
        <AmberUserButton
          testid="dashboard-user-btn"
          text={user.email}
          handleClick={handleOpenMenuUser}
        />
        <AmberMenuItems
          testid="dashboard-user-menu"
          anchorEl={anchorElUser}
          handleCloseMenu={handleCloseMenuUser}
        >
          <Box minWidth={{ xs: 180, sm: 200, md: 220, lg: 220, xl: 250 }}>
            <AmberMenuListItemMaker
              listItems={getListUserItems(user)}
              handleCloseMenuUser={handleCloseMenuUser}
            />

            {/* Render the organization item accordingly */}
            {isOrgMember ? organizationItem : null}

            {/* Language selector */}
            <MenuItem divider disableRipple>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                paddingTop={2}
                paddingBottom={2}
              >
                <AmberText
                  testid="dashboard-user-item-language"
                  text={t('UPLOAD_FORM.translatedSubtitles.language')}
                />
                <LanguageSelectorWithUserUpdate />
              </Box>
            </MenuItem>

            <MenuItem divider disableRipple>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="baseline"
                width="100%"
              >
                <AmberText
                  testid="dashboard-user-item-new-features"
                  text={t('ACCOUNT.newFeatures')}
                  onClick={() => {
                    window.open('https://www.amberscript.com/en/release-notes/', '_blank').focus();
                  }}
                />
                <AmberSmallTextLabel
                  testid="dashboard-user-item-language"
                  text={t('ACCOUNT.latestImprovements')}
                  color="custom.infoMessage"
                  fontSize="0.7rem !important"
                />
              </Box>
            </MenuItem>

            {/* Logout */}
            <MenuItem onClick={handleLogout}>
              <Box
                marginTop={{ xs: 1, sm: 1, md: 1.5, lg: 2, xl: 2 }}
                marginBottom={{ xs: 1, sm: 1, md: 1.5, lg: 2, xl: 2 }}
                paddingTop={2}
                paddingBottom={2}
              >
                <AmberTextError
                  testid="dashboard-user-item-logout"
                  text={t('MISC.logout')}
                />
              </Box>
            </MenuItem>
          </Box>
        </AmberMenuItems>
      </Box>
    );
  }

  return null;
}
