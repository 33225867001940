import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberSmallTextLabel(props) {
  const { text, textAlign, color, marginLeft, marginRight, fontSize } = props;

  return (
    <Box
      component="span"
      textAlign={textAlign}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      color={color || 'unset'}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <Typography fontSize={fontSize || 'unset'} variant="small">{text}</Typography>
    </Box>
  );
}

AmberSmallTextLabel.propTypes = {
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  color: PropTypes.string,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  fontSize: PropTypes.string,
};

AmberSmallTextLabel.defaultProps = {
  textAlign: 'left',
  color: 'unset',
  marginLeft: 0,
  marginRight: 0,
  fontSize: 'unset',
};
