import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  InputBase,
  Typography,
} from '@mui/material';

const CustomInputBase = styled(InputBase)(
  () => `
        border: none !important;    
        fieldset: {
            border: none !important;
        }
    `,
);

export default function AmberSelectNoBorder(props) {
  const {
    label = null,
    value,
    onChange,
    emptyOption = false,
    options,
    helperText = null,
    testid,
  } = props;

  return (
    <FormControl sx={{ m: 1, minWidth: 80 }}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        labelId="language-select-autowidth"
        value={value}
        onChange={onChange}
        input={<CustomInputBase sx={{ fontWeight: 600 }} />}
        data-testid={testid}
      >
        {emptyOption && (
          <MenuItem value="">
            <em>-</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Typography variant="normal">{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

AmberSelectNoBorder.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyOption: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  helperText: PropTypes.string,
  testid: PropTypes.string,
};

AmberSelectNoBorder.defaultProps = {
  label: null,
  helperText: null,
  emptyOption: false,
  testid: '',
};
