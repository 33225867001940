/**
 * Get available languages to be selected by users
 * @param {useTranslation} t
 */
export default function getAvailableLanguages(t) {
  return [
    { label: t('LANGUAGES.en'), value: 'en' },
    { label: t('LANGUAGES.nl'), value: 'nl' },
    { label: t('LANGUAGES.de'), value: 'de' },
    { label: t('LANGUAGES.fr'), value: 'fr' },
    { label: t('LANGUAGES.es'), value: 'es' },
    { label: t('LANGUAGES.it'), value: 'it' },
    { label: t('LANGUAGES.pt'), value: 'pt' },
  ];
}
