/* eslint-disable prefer-template */
import moment from 'moment';

export function convertSecondsToHHHHMMSS(givenSeconds) {
  let negative = false;
  let sec = givenSeconds;
  if (givenSeconds < 0) {
    sec *= -1;
    negative = true;
  }
  const secondsNumber = parseInt(sec, 10);
  let hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
  let seconds = secondsNumber - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${(negative ? '-' : '') + hours}:${minutes}:${seconds}`;
}

/**
 * Seconds to {h: x, m: y, s: z}
 * @param  {number}
 * @return {object}
 */
export function convertSecondsToObjectHHMMSS(givenSeconds) {
  let negative = false;
  let sec = givenSeconds;
  if (givenSeconds < 0) {
    sec *= -1;
    negative = true;
  }
  const secondsNumber = parseInt(sec, 10);
  let hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
  let seconds = secondsNumber - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return {
    h: hours,
    m: minutes,
    s: seconds,
    isNegative: negative,
  };
}

/**
 * Seconds to HH:MM:SS
 * @param  {number}
 * @return {string}
 */
export function convertSecondsToHHMMSS(seconds) {
  if (Number.isInteger(seconds)) {
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  }
  return '';
}

/**
 * Seconds to HH:MM:SS
 * @param  {number}
 * @return {string}
 */
export function convertMinutesToHHMMSS(minutes) {
  if (Number.isInteger(minutes)) {
    return moment.utc(minutes * 60000).format('HH:mm:ss');
  }
  return '';
}

/**
 * Timestamps to DD-MM-YYYY
 * @param  {number}
 * @return {string}
 */
export function convertSecondsToDDMMYYYY(timestamps) {
  if (Number.isInteger(timestamps)) {
    return moment(new Date(timestamps)).format('DD-MM-YYYY');
  }
  return '';
}

/**
 * Timestamps to DD-MM-YYYY HH:MM:SS
 * @param  {number}
 * @return {string}
 */
export function convertSecondsToDDMMYYYYHHMMSS(timestamps) {
  if (Number.isInteger(timestamps)) {
    return moment(new Date(timestamps)).format('DD-MM-YYYY HH:mm:ss');
  }
  return '';
}

/**
 * Seconds to duration
 * @param  {number}
 * @return {string}
 */
export function convertSecondsToDuration(seconds) {
  if (Number.isInteger(seconds)) {
    return `${moment.duration(seconds, 'seconds').asHours().toFixed(2)} hours`;
  }
  return '';
}

/**
 * Seconds to minutes
 * @param {number} seconds
 */
export function convertSecondsToMinutes(seconds) {
  if (Number.isInteger(seconds)) {
    return parseFloat(seconds / 60).toFixed(2);
  }
  return 0;
}

/**
 * Seconds to milliseconds
 * @param {number} seconds
 */
export const convertSecondsToMs = (seconds) => Math.ceil(seconds * 1000);

/**
 * Seconds to hours, minutes seconds and milliseconds
 * @param {number} givenSeconds
 * @param {string} msDelimiter
 */
export function convertSecondsToHHMMSSMS(givenSeconds, msDelimiter) {
  let negative = false;
  let tempGivenSeconds = givenSeconds;
  if (givenSeconds < 0) {
    tempGivenSeconds *= -1;
    negative = true;
  }
  const secondsNumber = parseInt(tempGivenSeconds, 10);
  let hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
  let seconds = secondsNumber - hours * 3600 - minutes * 60;
  let milliSeconds = Math.round((tempGivenSeconds % 1) * 1000);

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (!milliSeconds) {
    milliSeconds = '000';
  } else if (milliSeconds < 10) {
    milliSeconds = `00${milliSeconds}`;
  } else if (milliSeconds < 100) {
    milliSeconds = `0${milliSeconds}`;
  }

  if (!tempGivenSeconds) {
    return `00:00:00${msDelimiter}000`;
  }

  return (
    (negative ? '-' : '') +
    hours +
    ':' +
    minutes +
    ':' +
    seconds +
    msDelimiter +
    milliSeconds
  );
}

/**
 * Seconds to hours, minutes, seconds without milliseconds
 * @param {number (can be float)} givenSeconds
 */
export function convertSecondsToHHMMSSNoMS(givenSeconds) {
  let negative = false;
  let tempGivenSeconds = givenSeconds;
  if (givenSeconds < 0) {
    tempGivenSeconds *= -1;
    negative = true;
  }
  const secondsNumber = parseInt(tempGivenSeconds, 10);
  let hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
  let seconds = secondsNumber - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  if (!tempGivenSeconds) {
    return '00:00:00';
  }

  return (negative ? '-' : '') + hours + ':' + minutes + ':' + seconds;
}

/**
 * Returns the current formatted date and time
 * @returns {string} date
 */
export function getCurrentTime() {
  const timeElapsed = Date.now();
  const dateTime = new Date(timeElapsed);
  return dateTime.toUTCString();
}
