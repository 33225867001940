import Geomanist from '../assets/fonts/Geomanist-Regular.otf';
import GeomanistBold from '../assets/fonts/Geomanist-Bold.otf';
import GeomanistMedium from '../assets/fonts/Geomanist-Medium.otf';

const AMBER_FONTS = `
    @font-face {
      font-family: 'Geomanist';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: local('Geomanist-Regular'), url(${Geomanist}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    };
    @font-face {
      font-family: 'Geomanist-Bold';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: local('Geomanist-Bold'), url(${GeomanistBold}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }
    @font-face {
      font-family: 'Geomanist-Medium';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: local('Geomanist-Medium'), url(${GeomanistMedium}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    }
  `;

export default AMBER_FONTS;
