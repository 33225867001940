import axios from 'axios';
import apiClient from './apiClient';
import { getCookie } from '../helpers/cookie';
import { getParamValueByKey } from '../helpers/url';
import { signInWithEmailPassword } from '../helpers/firebaseAuthentication';
import parseFeatureToggle from '../helpers/featureToggles';
import { trackSentryErrorByTag } from '../sentry/common';

/**
 * Logs the user with the username and password
 * @param {string} username username of the user
 * @param {string} password password of the user
 */
export async function login(username, password) {
  const URL = `${process.env.REACT_APP_BE_BASE_URL}/v2/token`;

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const auth = btoa(`${clientId}:${clientSecret}`);

  try {
    await axios.post(
      URL,
      {
        username,
        password,
      },
      {
        headers: {
          Authorization: auth,
        },
        withCredentials: true,
      },
    );
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Check if the email address entered by the user is not used before
 * @param {string} email Email address entered by the user
 */
export function emailCheck(email) {
  return apiClient.post(
    'emailcheck',
    {},
    {
      params: {
        email,
      },
    },
  );
}

/**
 * Get user's country by IP
 */
export function getCountryByIP() {
  return apiClient.get('countrybyip');
}

/**
 * Get all countries
 */
export function getCountries() {
  return apiClient.get('user/countries');
}

/**
 * Get all sectors
 */
export function getSectors() {
  return apiClient.get('user/sectors');
}

/**
 * Reset the password of the user
 * @param {string} userName userName of the user
 * @param {string} password password of the user
 * @param {string} recoverKey recoverKey presented in the URL
 */
export function resetPassword(userName, password, recoverKey) {
  return apiClient.post('user/changepwd', { userName, password, recoverKey });
}

/**
 * Send password reset link to user's email
 * @param {string} email email of the user
 */
export function sendEmailResetPassword(email) {
  return apiClient.post('user/recoverpwd', { email });
}

function getAnalyticsData(keys) {
  const analytics = {};

  // add datalayer values from cookies
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const value = getCookie(key);
    if (value) {
      analytics[key] = value;
    }
  }

  // if value not present in cookies, check current url and use the value
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const value = getParamValueByKey[key];
    if (!analytics[key] && value) {
      analytics[key] = value;
    }
  }

  if (analytics.utm_source) {
    // do nothing, keep it
  } else if (analytics.gclid) {
    analytics.utm_source = 'adwords';
  } else if (analytics.referrer && analytics.referrer.includes('google')) {
    analytics.utm_source = 'organic';
  } else {
    analytics.utm_source = 'direct';
  }

  analytics.googleAnalyticsClientId = getCookie('_gid');

  return analytics;
}

/**
 * Register user
 * @param {string} email email of the user
 * @param {string} firstName firstName of the user
 * @param {string} lastName lastName of the user
 * @param {string} countryCode countryCode of the user
 * @param {string} language language of the user
 * @param {string} password password of the user
 * @param {string} sectorCode sectorCode of the user
 * @param {boolean} isUploadBeforeSignup flag from upload before signup flow
 */
export async function register(
  email,
  firstName,
  lastName,
  countryCode,
  language,
  password,
  sectorCode,
  signupProducts,
  signupVolume,
  organizationId,
  referralCode,
  isUploadBeforeSignup,
) {
  const NEW_LOGIN_ENABLED = parseFeatureToggle(
    process.env.REACT_APP_FF_NEW_LOGIN,
  );
  try {
    const analyticsKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'user_id',
      'utm_term',
      'gclid',
      'referrer',
      'hubspotutk',
    ];
    const userCreationResponse = await apiClient.post(
      'user/add',
      {
        analytics: getAnalyticsData(analyticsKeys),
        email,
        firstName,
        lastName,
        countryCode,
        language,
        password,
        sectorCode,
        isBusinessUser: false,
        couponCode: '',
        signupProducts,
        signupVolume,
        organizationId: organizationId || undefined,
      },
      {
        params: {
          organizationId: organizationId || undefined,
          validationUrl: `${window.location.origin}/emailvalidate`,
          referralCode: referralCode || undefined,
        },
      },
    );

    if (NEW_LOGIN_ENABLED && !isUploadBeforeSignup) {
      await signInWithEmailPassword(email, password);
    } else {
      await login(email, password);
    }

    return userCreationResponse?.data;
  } catch (error) {
    trackSentryErrorByTag(error, 'user-signup-error');
    throw error;
  }
}

/**
 * Logs out the user
 */
export async function logout() {
  try {
    await apiClient.post('logout');
    await apiClient.post('session/logout');
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
}
