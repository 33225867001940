import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Box } from '@mui/material';
import { viewRedeemCouponDialogAtom } from '../../../store/redeemCoupon';
import { forceUserUpdate } from '../../../store/user';
import AmberCustomDialog from '../../Common/Dialogs/AmberCustomDialog';
import AmberTextField from '../../Common/Inputs/AmberTextField';
import { submitCouponCode } from '../../../api/coupons';
import AmberLoadingButton from '../../Common/Buttons/AmberLoadingButton';
import useNotification from '../../../helpers/customHooks/notifications';
import { AMBER_NOTIFICATION_SUCCESS } from '../../../helpers/constants';

export default function RedeemCouponDialog() {
  const [t] = useTranslation();
  const [couponCode, setCouponCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showNotification] = useNotification();

  const userUpdate = useSetRecoilState(forceUserUpdate);
  const updateUserValue = () => userUpdate((n) => n + 1);

  const [viewRedeemCouponDialog, setViewRedeemCouponDialog] = useRecoilState(
    viewRedeemCouponDialogAtom,
  );

  function openDialog() {
    setViewRedeemCouponDialog({
      open: true,
    });
  }

  function closeDialog() {
    setViewRedeemCouponDialog({
      open: false,
    });
  }

  const handleClose = () => {
    setIsError(false);
    setCouponCode('');
    setErrorMessage('');
    setBtnSubmitDisabled(true);

    closeDialog();
  };

  const handlePostSubmit = () => {
    setIsError(false);
    setCouponCode('');
    setErrorMessage('');
    setBtnSubmitDisabled(true);

    updateUserValue();
    closeDialog();
  };

  const handleCouponChange = (event) => {
    const newValue = event.target.value;
    setCouponCode(event.target.value);

    const isNewValueInvalid = newValue.trim().length === 0;
    setBtnSubmitDisabled(isNewValueInvalid);
  };

  const getErrorMessage = (errorData) => {
    if (errorData.errorCode === 'invalid_coupon_type') {
      return t('COUPON.invalidMinutesCouponType');
    }
    if (
      errorData.message &&
      errorData.message.includes('was already claimed')
    ) {
      return t('COUPON.alreadyClaimed');
    }
    if (errorData.message) {
      return errorData.message;
    }
    return t('COUPON.invalidCouponCode');
  };

  const handleSubmit = () => {
    setLoading(true);
    setIsError(false);
    setErrorMessage('');

    submitCouponCode(couponCode)
      .then((response) => {
        setIsError(false);
        let message = 'Success!';
        if (response && response.data && response.data.message) {
          message = response.data.message;
        }
        handlePostSubmit();
        showNotification(AMBER_NOTIFICATION_SUCCESS, message);
      })
      .catch((error) => {
        setLoading(false);
        setIsError(true);
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = getErrorMessage(errorData, t);
          setErrorMessage(message);
        } else {
          setErrorMessage(t('COUPON.invalidCouponCode'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const isReedemInURL = window.location.pathname.startsWith('/redeem');
    if (isReedemInURL) {
      openDialog();
    }
  }, []);

  const dialogAction = (
    <AmberLoadingButton
      testid="coupon-dialog-submit-btn"
      text={t('COUPON.submit')}
      handleClick={handleSubmit}
      loading={loading}
      disabled={btnSubmitDisabled}
    />
  );

  const dialogContent = (
    <Box pt={2}>
      <AmberTextField
        testid="coupon-dialog-code-input"
        id="email"
        label={t('COUPON.inputLabel')}
        value={couponCode}
        handleChange={handleCouponChange}
        error={isError}
        helperText={isError ? errorMessage : ''}
      />
    </Box>
  );

  if (viewRedeemCouponDialog.open) {
    return (
      <AmberCustomDialog
        testId="coupon-dialog"
        maxWidth="sm"
        title={t('COUPON.title')}
        open={viewRedeemCouponDialog.open}
        dialogContent={dialogContent}
        dialogAction={dialogAction}
        handleClose={handleClose}
        btnX
      />
    );
  }

  return null;
}
