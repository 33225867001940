import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';

import { getUserSelector } from '../../../store/user';
import { RECOIL_LOADABLE_HAS_VALUE } from '../../../store/constants';
import AmberMenuItems from '../../Common/Menus/AmberMenuItems';
import AmberAdminButton from '../../Common/Buttons/AmberAdminButton';
import AmberMenuListItemMaker from '../../Common/Menus/AmberMenuListItemMaker';
import { isAdmin } from '../../../helpers/roles';

export default function AdminMenu() {
  const userLoadable = useRecoilValueLoadable(getUserSelector);

  const [t] = useTranslation();

  // List of Admin menu items
  const listAdminItems = [
    {
      id: 1,
      address: '/admin/users',
      title: 'NAV.titleManageUsers',
      testid: 'dashboard-admin-item-users',
    },
    {
      id: 2,
      address: '/admin/add/user',
      title: 'NAV.titleAddUser',
      testid: 'dashboard-admin-item-adduser',
    },
    {
      id: 3,
      address: '/admin/invoices',
      title: 'NAV.titleManageInvoices',
      testid: 'dashboard-admin-item-invoices',
    },
    {
      id: 4,
      address: '/admin/organizations',
      title: 'NAV.titleManageOrganizations',
      testid: 'dashboard-admin-item-organizations',
    },
    {
      id: 5,
      address: '/admin/kalturaTasks',
      title: 'NAV.titleKalturaTasks',
      testid: 'dashboard-admin-item-kaltura',
    },
  ];

  // Anchors for drop down menu
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);

  // Opens the admin menu
  const handleOpenMenuAdmin = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  // Closes the admin menu
  const handleCloseMenuAdmin = () => {
    setAnchorElAdmin(null);
  };

  if (userLoadable.state === RECOIL_LOADABLE_HAS_VALUE) {
    const user = userLoadable.contents.data;

    if (isAdmin(user)) {
      return (
        <Box display={{ xs: 'none', sm: 'none', md: 'flex' }}>
          <AmberAdminButton
            testid="dashboard-admin-btn"
            label={t('V2.NAV.admin')}
            handleClick={handleOpenMenuAdmin}
          />
          <AmberMenuItems
            testid="dashboard-admin-menu"
            anchorEl={anchorElAdmin}
            handleCloseMenu={handleCloseMenuAdmin}
          >
            <Box minWidth={{ xs: 180, sm: 180, md: 200, lg: 200, xl: 200 }}>
              <AmberMenuListItemMaker listItems={listAdminItems} />
            </Box>
          </AmberMenuItems>
        </Box>
      );
    }
  }

  return null;
}
