import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useRecoilState } from 'recoil';
import { notificationAtom } from '../../../store/common';

// Vars
const VERTICAL_POSITION = 'bottom';
const HORIZONTAL_POSITION = 'center';
const AUTO_HIDE_DURATION = 2000;

// Functions
function CustomAlert(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}
const Alert = React.forwardRef(CustomAlert);

// Components
export default function AmberNotification() {
  const [notification, setNotification] = useRecoilState(notificationAtom);
  const { open, severity, message } = notification;

  if (!message && message.trim() === '') {
    return null;
  }

  function getBackgroundColorBySeverity() {
    if (severity === 'success') {
      return 'primary.main';
    }
    if (severity === 'error') {
      return 'error.main';
    }
    return 'primary.main';
  }

  const handleClose = () => {
    setNotification({
      open: false,
      severity,
      message,
    });
  };

  return (
    <Snackbar
      data-testid="snackbar-global-notification"
      anchorOrigin={{
        vertical: VERTICAL_POSITION,
        horizontal: HORIZONTAL_POSITION,
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={AUTO_HIDE_DURATION}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          width: '100%',
          backgroundColor: getBackgroundColorBySeverity(),
          color: 'white',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
