import apiClient from './apiClient';

/**
 * invite Users By Referral Code
 * @param {array} emailArray
 * @param {string} referralCode
 */

export function inviteUsersByReferralCode(emailArray, referralCode) {
  return apiClient.post('referral/invite', {
    emails: emailArray,
    referralCode,
  });
}

/**
 * get list of referred and invited users
 * @param {string} referralCode
 */

export function getReferralUsersByCode(referralCode) {
  return apiClient.get('referral/code', { params: { referralCode } });
}
