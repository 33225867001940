import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

export default function AmberPaperOutlinedSticky(props) {
  const { children } = props;

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        height: '100%',
        borderColor: '#ECECEC',
        position: 'sticky',
        top: '0',
        zIndex: '99',
      }}
    >
      {children}
    </Paper>
  );
}

AmberPaperOutlinedSticky.propTypes = {
  children: PropTypes.node.isRequired,
};
