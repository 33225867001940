import React from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function AmberDividerOr(props) {
  const { bgColor } = props;

  const [t] = useTranslation();

  return (
    <Box
      display="flex"
      position="relative"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        zIndex={1000}
        width="fit-content"
        position="absolute"
        sx={{
          backgroundColor: bgColor,
          paddingLeft: '12px',
          paddingRight: '12px',
        }}
      >
        <Box component="span" color="custom.infoMessage">
          {t('MISC.or')}
        </Box>
      </Box>
      <Box position="absolute" width="100%">
        <Divider sx={{ height: '2px', borderColor: 'custom.lightGray' }} />
      </Box>
    </Box>
  );
}

AmberDividerOr.propTypes = {
  bgColor: PropTypes.string,
};

AmberDividerOr.defaultProps = {
  bgColor: 'white',
};
