/* eslint-disable no-restricted-globals */

import { NON_USER_TOKEN } from './constants';
import { removeToken } from './storage';

/**
 * Gets the nonUserToken from the local storage
 * @returns {string} nonUserToken
 */
export function getNonUserToken() {
  return JSON.parse(localStorage.getItem(NON_USER_TOKEN));
}

/**
 * Returns true if url path is /upload-register
 * @returns {boolean}
 */
export function isUrlUploadBeforeSignup() {
  return location.pathname === '/upload-register';
}

/**
 * Cleans up the token if needed
 */
export function cleanUpNonUserToken() {
  if (!isUrlUploadBeforeSignup()) {
    removeToken(NON_USER_TOKEN);
  }
}
