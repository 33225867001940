import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AmberText from '../../../Common/Typography/AmberText';
import AmberDarkGreenTextButton from '../../../Common/Buttons/AmberDarkGreenTextButton';
import { resendWelcomeEmail } from '../../../../api/user';
import { emailNotificationAtom } from '../../../../store/emailNotification';
import HeaderNotification from '../HeaderNotification';

export default function EmailNotification(props) {
  const { user } = props;
  const [t] = useTranslation();

  if (user.emailValidated) return null;

  const [notificationContent, setNotificationContent] = useState();
  const [isOpen, setIsOpen] = useRecoilState(emailNotificationAtom);

  const closeNotification = () => {
    setIsOpen(false);
  };

  const SuccessContent = () => (
    <AmberText
      testid="email-notification-success-message"
      text={t('V2.EMAILNOTIFICATION.successMessage')}
    />
  );

  const handleResendVerification = () => {
    resendWelcomeEmail().then(() => {
      setNotificationContent(SuccessContent);
      setTimeout(closeNotification, 5000);
    });
  };

  const InitialContent = () => (
    <Box
      display="flex"
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
    >
      <Box mr={8} data-testid="email-notification-initial-message">
        <AmberText
          text={t('V2.EMAILNOTIFICATION.initialMessage', { email: user.email })}
        />
      </Box>
      <Box>
        <AmberDarkGreenTextButton
          text={t('V2.EMAILNOTIFICATION.resendButton')}
          handleClick={handleResendVerification}
          testid="email-notification-resend-button"
        />
      </Box>
    </Box>
  );

  const handleCloseClick = () => {
    closeNotification();
  };

  useEffect(() => {
    setNotificationContent(InitialContent);
  }, []);

  return (
    <HeaderNotification
      handleCloseClick={handleCloseClick}
      contentNode={notificationContent}
      isOpen={isOpen}
    />
  );
}

EmailNotification.propTypes = {
  user: PropTypes.shape().isRequired,
};
