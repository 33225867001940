import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { Box, Button } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AmberCustomDialog from '../../Common/Dialogs/AmberCustomDialog';
import useNotification from '../../../helpers/customHooks/notifications';
import {
  AMBER_NOTIFICATION_ERROR,
  AMBER_NOTIFICATION_SUCCESS,
} from '../../../helpers/constants';
import { viewReferralDialogAtom } from '../../../store/referral';
import AmberText from '../../Common/Typography/AmberText';
import AmberTextInputWithButton from '../../Common/Inputs/AmberTextInputWithButton';
import AmberTextBold from '../../Common/Typography/AmberTextBold';
import AmberDividerOr from '../../Common/Dividers/AmberDividerOr';
import ReferralInviteList from './referralInviteList';
import {
  getReferralUsersByCode,
  inviteUsersByReferralCode,
} from '../../../api/referral';
import { validateEmail } from '../../../helpers/validate';
import { RECOIL_LOADABLE_HAS_VALUE } from '../../../store/constants';
import { getUserSelector } from '../../../store/user';
import XIcon from '../../../assets/images/social/twitterx.png';

export default function ReferralDialogView() {
  const [t] = useTranslation();
  const [showNotification] = useNotification();
  const [emailInput, setEmailInput] = useState('');
  const [userReferralListAccepted, setUserReferralListAccepted] = useState([]);
  const [userReferralListPending, setUserReferralListPending] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emailButtonDisabled, setEmailButtonDisabled] = useState(true);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const userLoadable = useRecoilValueLoadable(getUserSelector);
  const [viewReferralDialog, setViewReferralDialog] = useRecoilState(
    viewReferralDialogAtom,
  );

  if (userLoadable.state !== RECOIL_LOADABLE_HAS_VALUE) {
    return <></>;
  }

  const user = userLoadable.contents.data;

  const { activeReferralCode } = user;

  const referralLink = `${process.env.REACT_APP_DASHBOARD_URL}/register?referralCode=${activeReferralCode}`;

  const shouldEnableAddButton = () => !validateEmail(emailInput);

  function closeDialog() {
    setEmailInput('');
    setIsLinkCopied(false);
    setViewReferralDialog({
      open: false,
    });
  }

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(
      () => {
        setIsLinkCopied(true);
        showNotification(
          AMBER_NOTIFICATION_SUCCESS,
          t('REFERRALDIALOG.linkCopied'),
        );
      },
      () => {
        showNotification(AMBER_NOTIFICATION_ERROR, 'Error');
      },
    );
  };

  const getUserReferralLists = () => {
    setIsLoading(true);

    getReferralUsersByCode(activeReferralCode)
      .then((response) => {
        setUserReferralListPending(
          response.data.referral.invitedEmails.reverse(),
        );
        setUserReferralListAccepted(response.data.referral.referredUsers);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const emailButtonDisbaledHandler = () => {
    if (shouldEnableAddButton()) {
      setEmailButtonDisabled(true);
    } else {
      setEmailButtonDisabled(false);
    }
  };

  const inviteUsersByEmailAndCode = () => {
    setEmailButtonDisabled(true);
    inviteUsersByReferralCode([emailInput], activeReferralCode)
      .then(() => {
        getUserReferralLists();
        setEmailInput('');
        showNotification(
          AMBER_NOTIFICATION_SUCCESS,
          t('REFERRALDIALOG.invited'),
        );
      })
      .catch(() => {})
      .finally(() => {
        setEmailButtonDisabled(true);
      });
  };

  const handleClose = () => {
    closeDialog();
  };

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleCopyClick = () => {
    copyCodeToClipboard();
  };

  const handleInviteClick = () => {
    inviteUsersByEmailAndCode();
  };

  useEffect(() => {
    emailButtonDisbaledHandler();
  }, [emailInput]);

  useEffect(() => {
    getUserReferralLists();
  }, []);

  // Share handlers
  const shareOnTwitter = () => {
    const url = encodeURIComponent(referralLink);
    const text = encodeURIComponent(t('REFERRALDIALOG.shareTwitterMessage'));
    window.open(
      `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      '_blank',
    );
  };

  const shareOnFacebook = () => {
    const url = encodeURIComponent(referralLink);
    const title = t('REFERRALDIALOG.shareFacebookTitle');
    const body = t('REFERRALDIALOG.shareFacebookMessage');

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}%0A%0A${body}`,
      '_blank',
    );
  };

  const shareOnWhatsApp = () => {
    const text = encodeURIComponent(
      t('REFERRALDIALOG.shareWhatsappMessage', { referralLink }),
    );
    window.open(`https://wa.me/?text=${text}`, '_blank');
  };

  const dialogAction = <></>;

  const dialogContent = (
    <Box pt={2}>
      <AmberText text={t('REFERRALDIALOG.body')} />
      <Box mt={6}>
        <AmberTextInputWithButton
          testid="referral-dialog-emailInput"
          handleChange={handleEmailInputChange}
          handleClick={handleInviteClick}
          value={emailInput}
          label={t('REFERRALDIALOG.addPlaceholder')}
          buttonLabel={t('REFERRALDIALOG.addButton')}
          buttonDisabled={emailButtonDisabled}
        />

        <ReferralInviteList
          isLoading={isLoading}
          userReferralListAccepted={userReferralListAccepted}
          userReferralListPending={userReferralListPending}
        />

        <Box mt={10} mb={10}>
          <AmberDividerOr />
        </Box>

        <AmberTextBold text={t('REFERRALDIALOG.secondTitle')} />

        <Box mt={2}>
          <AmberTextInputWithButton
            testid="referral-dialog-copyLink"
            handleChange={() => {}}
            handleClick={handleCopyClick}
            value={referralLink}
            label={referralLink}
            buttonLabel={
              isLinkCopied
                ? t('REFERRALDIALOG.linkCopied')
                : t('REFERRALDIALOG.copyButton')
            }
          />
        </Box>

        <Box
          mt={6}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={shareOnTwitter}
            startIcon={(
              <img
                src={XIcon}
                alt="Tweet"
                style={{ filter: 'invert(100%)', height: '100%' }}
              />
            )}
            style={{ color: 'white', backgroundColor: 'black' }}
          >
            Tweet
          </Button>
          <Button
            variant="contained"
            onClick={shareOnFacebook}
            startIcon={<FacebookOutlinedIcon style={{ color: 'white' }} />}
            style={{ backgroundColor: '#3b5998', color: 'white' }}
          >
            {t('REFERRALDIALOG.share')}
          </Button>
          <Button
            variant="contained"
            onClick={shareOnWhatsApp}
            startIcon={<WhatsAppIcon />}
            style={{ backgroundColor: '#25D366', color: 'white' }}
          >
            {t('REFERRALDIALOG.share')}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  if (viewReferralDialog.open) {
    return (
      <AmberCustomDialog
        testId="dialog-referrals"
        maxWidth="sm"
        title={t('REFERRALDIALOG.title')}
        open={viewReferralDialog.open}
        dialogContent={dialogContent}
        dialogAction={dialogAction}
        handleClose={handleClose}
        btnX
      />
    );
  }

  return null;
}
