import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Box, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AMBER_INFO_GRAY,
  AMBER_PRIMARY,
  GRAY_COLOR,
} from '../../../material-ui/colors';
import AmberTextBold from '../Typography/AmberTextBold';

const useStyles = makeStyles({
  buttonDisabled: {
    color: AMBER_INFO_GRAY,
    backgroundColor: GRAY_COLOR,
    cursor: 'inherit',
  },
  buttonEnabled: {
    color: 'white',
    backgroundColor: AMBER_PRIMARY,
    cursor: 'pointer',
  },
});

export default function AmberTextInputWithButton(props) {
  const {
    label,
    value,
    handleClick,
    handleChange,
    buttonLabel,
    buttonDisabled,
    testid,
  } = props;
  const classes = useStyles();

  const handleButtonClick = () => (buttonDisabled ? () => {} : handleClick());

  const EndAdornmentDisplayer = () => (
    <InputAdornment position="end">
      <Box
        data-testid={`${testid}-button`}
        borderRadius="0 4px 4px 0"
        height="56px"
        p={4}
        onClick={handleButtonClick}
        width="max-content"
        display="flex"
        alignItems="center"
        className={
          buttonDisabled ? classes.buttonDisabled : classes.buttonEnabled
        }
      >
        <AmberTextBold text={buttonLabel} />
      </Box>
    </InputAdornment>
  );

  return (
    <OutlinedInput
      data-testid={testid}
      id="outlined-adornment-password"
      placeholder={label}
      value={value}
      onChange={handleChange}
      sx={{ pr: 0, borderRadius: '4px', width: '100%' }}
      endAdornment={<EndAdornmentDisplayer />}
    />
  );
}

AmberTextInputWithButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
  testid: PropTypes.string,
};

AmberTextInputWithButton.defaultProps = {
  buttonDisabled: false,
  testid: '',
};
