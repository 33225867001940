import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

import AmberText from '../../Common/Typography/AmberText';
import AmberTitle from '../../Common/Typography/AmberTitle';
import theme from '../../../material-ui/theme';

const CustomButton = styled(Button)(() => ({
  textTransform: 'none',
  height: '100%',
  paddingLeft: { xs: 0.6, sm: 0.8, md: 1 },
  paddingRight: { xs: 0.6, sm: 0.8, md: 1 },
}));

export default function AmberBalanceButton(props) {
  const {
    balance,
    disabled = false,
    handleClick,
    fullWidth = false,
    testid = '',
  } = props;

  const [t] = useTranslation();

  return (
    <CustomButton
      data-testid={testid}
      variant="text"
      disabled={disabled}
      onClick={handleClick}
      fullWidth={fullWidth}
      disableRipple
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          display={{ xs: 'none', sm: 'flex' }}
          marginLeft={2}
          marginRight={2}
        >
          <AmberText
            text={t('V2.NAV.balance')}
            color={theme.palette.custom.charcoalGray}
          />
        </Box>
        <Box marginLeft={{ xs: 2, sm: 0 }} marginRight={{ xs: 0, sm: 2 }}>
          <AmberTitle
            text={`${balance.isNegative ? '-' : ''}${balance.h}h ${
              balance.m
            }m ${balance.s}s`}
            testid="dashboard-balance-btn-value"
          />
        </Box>
        <KeyboardArrowDownIcon color="action" />
      </Box>
    </CustomButton>
  );
}

AmberBalanceButton.propTypes = {
  balance: PropTypes.shape({
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    s: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isNegative: PropTypes.bool.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  testid: PropTypes.string,
};

AmberBalanceButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  testid: '',
};
