import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingPage from './LoadingPage';
import DashboardBase from './User/Base/DashboardBase';
import AmberTitleHeader from './Common/Typography/AmberTitleHeader';
import AmberText from './Common/Typography/AmberText';
import AmberPaperOutlinedSticky from './Common/Papers/AmberPaperOutlinedSticky';

import { ReactComponent as NotFoundImage } from '../assets/images/NotFound.svg';
import AmberscriptLogo from '../assets/images/AmberscriptLogo.svg';
import AmberLogoBlack from '../assets/images/AmberLogoBlack.svg';
import AmberPrimaryButton from './Common/Buttons/AmberPrimaryButton';

// Logo
function Logo() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      height="100%"
      maxWidth={{ xs: 100, sm: 140, md: 180, lg: 200, xl: 240 }}
    >
      <Box display={{ xs: 'none', sm: 'flex' }}>
        <img
          src={AmberscriptLogo}
          alt="logo"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box display={{ xs: 'flex', sm: 'none' }} height="60%">
        <img
          src={AmberLogoBlack}
          alt="logo-A"
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  );
}

function NotFoundUserLoggedIn() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const redirectUserToDashboard = () => {
    navigate('/records', { replace: true });
  };

  return (
    <DashboardBase>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={-2}
          >
            <Box>
              <NotFoundImage />
            </Box>
            <Box marginTop={4}>
              <AmberTitleHeader text={t('V2.MISC.pageNotFoundTitle')} />
            </Box>
            <Box marginTop={2}>
              <AmberText text={t('V2.MISC.pageNotFoundDesc')} />
            </Box>

            <Box marginTop={8}>
              <AmberPrimaryButton
                handleClick={redirectUserToDashboard}
                text={t('EMAILVALIDATE.loggedinActionBtn')}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DashboardBase>
  );
}

function NotFoundUserNotLoggedIn() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();

  const redirectUserToLoginPage = () => {
    navigate('/login', { replace: true });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={theme.palette.table.headerBackground}
      minHeight="100vh"
      maxHeight="100vh"
      overflow="hidden"
    >
      <AmberPaperOutlinedSticky>
        <Box
          position="sticky"
          top={0}
          display="flex"
          alignItems="center"
          height={{ xs: 50, sm: 60, md: 70 }}
          marginLeft={{ xs: 4, sm: 5, md: 6, lg: 7 }}
          marginRight={{ xs: 1, sm: 1, md: 2, lg: 3 }}
        >
          {/* Left */}
          <Box
            height="100%"
            maxWidth={{ xs: 120, sm: 140, md: 160, lg: 160, xl: 200 }}
          >
            <Logo />
          </Box>
        </Box>
      </AmberPaperOutlinedSticky>

      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        paddingBottom={{ xs: 4, sm: 6, md: 7, lg: 7, xl: 8 }}
        paddingLeft={{ xs: 4, sm: 6, md: 7, lg: 7, xl: 8 }}
        paddingRight={{ xs: 4, sm: 6, md: 7, lg: 7, xl: 8 }}
        paddingTop={6}
        overflow="hidden"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              marginTop={-2}
            >
              <Box>
                <NotFoundImage />
              </Box>
              <Box marginTop={4}>
                <AmberTitleHeader text={t('V2.MISC.pageNotFoundTitle')} />
              </Box>
              <Box marginTop={2}>
                <AmberText text={t('V2.MISC.pageNotFoundDesc')} />
              </Box>

              <Box marginTop={8}>
                <AmberPrimaryButton
                  handleClick={redirectUserToLoginPage}
                  text={t('REGISTER.alreadyAction')}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default function NotFound() {
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(async () => {
    try {
      const baseURL = `${process.env.REACT_APP_BE_BASE_URL}/v2`;

      const result = await axios.get(`${baseURL}/user/get`, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (result && result.data) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingPage />;
  }
  if (isAuthenticated) {
    return <NotFoundUserLoggedIn />;
  }
  return <NotFoundUserNotLoggedIn />;
}
