/**
 * Return the link for Terms and Conditions
 * @param {string} lang
 * @returns link in the correct language
 */
export function getTermsAndConditionPageForSpecificLanguage(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/terms-and-conditions';
    case 'de':
      return 'https://www.amberscript.com/de/nutzungsbedingungen';
    case 'fr':
      return 'https://www.amberscript.com/fr/termes-et-conditions';
    case 'nl':
      return 'https://www.amberscript.com/nl/gebruikersvoorwaarden';
    case 'sv':
      return 'https://www.amberscript.com/sv/villkor';
    case 'pt':
      return 'https://www.amberscript.com/pt/termos-e-condicoes';
    case 'es':
      return 'https://www.amberscript.com/es/terminos-y-condiciones';
    case 'dk':
      return 'https://www.amberscript.com/dk/terms-and-conditions';
    case 'it':
      return 'https://www.amberscript.com/it/termini-e-condizioni-d-uso';
    default:
      return 'https://www.amberscript.com/en/terms-and-conditions';
  }
}

/**
 * Return the link for Perfect pricing
 * @param {string} lang
 * @returns link in the correct language
 */
export function getMoreInformationAboutPerfectPricingLinkByLanguage(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/minimum-fee-perfect-manual-transcription';
    case 'de':
      return 'https://www.amberscript.com/de/minimum-tariff-perfect-manuelle-transkription';
    case 'fr':
      return 'https://www.amberscript.com/fr/tarif-minimum-transcription-manuelle-perfect';
    case 'nl':
      return 'https://www.amberscript.com/nl/minimum-tarief-perfect-handmatige-transcriptie';
    case 'sv':
      return 'https://www.amberscript.com/sv/minimum-fee-perfect-manual-transcription';
    case 'pt':
      return 'https://www.amberscript.com/pt/tarifa-minima-transcricao-manual';
    case 'es':
      return 'https://www.amberscript.com/es/tarifa-minima-transcripcion-manual-perfect';
    case 'dk':
      return 'https://www.amberscript.com/da/minimum-pris-perfect-manuel-transskription';
    case 'it':
      return 'https://www.amberscript.com/it/tariffa-minima-trascrizione-manuale-perfect';
    default:
      return 'https://www.amberscript.com/en/minimum-fee-perfect-manual-transcription';
  }
}

/**
 * Return the link for Perfect transcription manual
 * @param {string} lang
 * @returns link in the correct language
 */
export function getManualServiceTable(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/perfect-manual-transcription';
    case 'de':
      return 'https://www.amberscript.com/de/perfect-manuelle-transkription';
    case 'fr':
      return 'https://www.amberscript.com/fr/perfect-transcription-manuelle';
    case 'nl':
      return 'https://www.amberscript.com/nl/perfect-handmatige-transcriptie';
    case 'sv':
      return 'https://www.amberscript.com/sv/perfect-manuell-transkription';
    case 'pt':
      return 'https://www.amberscript.com/pt/perfect-transcricao-manual';
    case 'es':
      return 'https://www.amberscript.com/es/perfect-transcripcion-manual';
    case 'dk':
      return 'https://www.amberscript.com/da/perfect-manual-transkription';
    case 'it':
      return 'https://www.amberscript.com/it/perfect-trascrizione-manuale';
    default:
      return 'https://www.amberscript.com/en/perfect-manual-transcription';
  }
}

/**
 * Return the link for List of pricing
 * @param {string} lang
 * @returns link in the correct language
 */
export function getListOfPricings(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/pricing';
    case 'de':
      return 'https://www.amberscript.com/de/preise';
    case 'fr':
      return 'https://www.amberscript.com/fr/prix';
    case 'nl':
      return 'https://www.amberscript.com/nl/prijzen';
    case 'sv':
      return 'https://www.amberscript.com/sv/prislista';
    case 'pt':
      return 'https://www.amberscript.com/pt/precos';
    case 'es':
      return 'https://www.amberscript.com/es/precios';
    case 'dk':
      return 'https://www.amberscript.com/da/prisliste';
    case 'it':
      return 'https://www.amberscript.com/it/prezzi';
    default:
      return 'https://www.amberscript.com/en/pricing';
  }
}

/**
 * Return the link for Request a quote
 * @param {string} lang
 * @returns link in the correct language
 */
export function getRequestQuoteLinkByLang(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/request-a-quote/';
    case 'de':
      return 'https://www.amberscript.com/de/angebot-anfordern/';
    case 'fr':
      return 'https://www.amberscript.com/fr/demandez-un-devis/';
    case 'nl':
      return 'https://www.amberscript.com/nl/offerte-opvragen/';
    case 'sv':
      return 'https://www.amberscript.com/sv/begar-en-offert/';
    case 'pt':
      return 'https://www.amberscript.com/pt-pt/solicite-um-orcamento/';
    case 'es':
      return 'https://www.amberscript.com/es/solicitar-un-presupuesto/';
    case 'dk':
      return 'https://www.amberscript.com/da/anmod-om-et-tilbud/';
    case 'it':
      return 'https://www.amberscript.com/it/richiedi-un-preventivo/';
    default:
      return 'https://www.amberscript.com/en/request-a-quote/';
  }
}

/**
 * Return the link for Contact us
 * @param {string} lang
 * @returns link in the correct language
 */
export function getContactUsByLang(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/contact-us';
    case 'de':
      return 'https://www.amberscript.com/de/kontakt';
    case 'fr':
      return 'https://www.amberscript.com/fr/contactez-nous';
    case 'nl':
      return 'https://www.amberscript.com/nl/contacteer-ons';
    case 'sv':
      return 'https://www.amberscript.com/sv/kontakta-oss';
    case 'pt':
      return 'https://www.amberscript.com/pt-pt/contate-nos';
    case 'es':
      return 'https://www.amberscript.com/es/contactenos';
    case 'dk':
      return 'https://www.amberscript.com/da/kontakt-os';
    case 'it':
      return 'https://www.amberscript.com/it/contatti';
    default:
      return 'https://www.amberscript.com/en/contact-us';
  }
}

/**
 * Return the link for Help desk
 * @param {string} lang
 * @returns link in the correct language
 */
export function getHelpDeskByLang(lang) {
  switch (lang) {
    case 'en':
      return 'https://helpdesk.amberscript.com/en';
    case 'de':
      return 'https://helpdesk.amberscript.com/de';
    case 'fr':
      return 'https://helpdesk.amberscript.com/fr';
    case 'nl':
      return 'https://helpdesk.amberscript.com/nl';
    case 'sv':
      return 'https://helpdesk.amberscript.com/sv';
    case 'pt':
      return 'https://helpdesk.amberscript.com/pt-br';
    case 'es':
      return 'https://helpdesk.amberscript.com/es';
    case 'dk':
      return 'https://helpdesk.amberscript.com/da';
    case 'it':
      return 'https://helpdesk.amberscript.com/it';
    default:
      return 'https://helpdesk.amberscript.com/en';
  }
}

/**
 * Return the file expiration link for Help desk
 * @param {string} lang
 * @returns link in the correct language
 */
export function getHelpDeskFileExpirationByLang(lang) {
  switch (lang) {
    case 'en':
      return 'https://helpdesk.amberscript.com/en/file-expiration';
    case 'de':
      return 'https://helpdesk.amberscript.com/de/ablauf-der-datei';
    case 'fr':
      return 'https://helpdesk.amberscript.com/fr/expiration-de-fichiers';
    case 'nl':
      return 'https://helpdesk.amberscript.com/nl/bestandsverloop';
    case 'sv':
      return 'https://helpdesk.amberscript.com/sv/filutgång';
    case 'pt':
      return 'https://helpdesk.amberscript.com/pt-br/expiração-do-arquivo';
    case 'es':
      return 'https://helpdesk.amberscript.com/es/expiración-del-archivos';
    case 'dk':
      return 'https://helpdesk.amberscript.com/da/filudløb';
    case 'it':
      return 'https://helpdesk.amberscript.com/it/espirazione-file';
    default:
      return 'https://helpdesk.amberscript.com/en/file-expiration';
  }
}

/**
 * Return the link for case study
 * @param {string} lang
 * @returns link in the correct language
 */
export function getCaseStudyLink(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/academy/how-camfactor-delivers-captioned-24kitchen-shows/';
    case 'de':
      return 'https://www.amberscript.com/de/academy/camfactors-24kitchen-sendungen-erober-mit-untertiteln-den-internationalen-markt/';
    case 'fr':
      return 'https://www.amberscript.com/fr/academy/transcription-verbatim-universite-montpellier/';
    case 'nl':
      return 'https://www.amberscript.com/nl/academy/hva-case-study/';
    case 'pt':
      return 'https://www.amberscript.com/es/academy/transcripcion-manual-universitat-barcelona/';
    case 'es':
      return 'https://www.amberscript.com/es/academy/transcripcion-manual-universitat-barcelona/';
    case 'it':
      return 'https://www.amberscript.com/it/academy/sporters-sottotitoli-manuali/';
    default:
      return 'https://www.amberscript.com/en/academy/how-camfactor-delivers-captioned-24kitchen-shows/';
  }
}

/**
 * Return the right integrations helpdesek page
 * @param {string} lang
 * @returns link in the correct language
 */
export function getIntegrationsPage() {
  return 'https://helpdesk.amberscript.com/en/integrations/';
}

/**
 * Return the right API page
 * @param {string} lang
 * @returns link in the correct language
 */
export function getAPIpage(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/products/api-custom-models/';
    case 'de':
      return 'https://www.amberscript.com/de/produkte/api-kundenspezifische-spracherkennung/';
    case 'fr':
      return 'https://www.amberscript.com/fr/produits/api-modeles-personnalises/';
    case 'nl':
      return 'https://www.amberscript.com/nl/producten/spraak-naar-tekst-api/';
    case 'pt':
      return 'https://www.amberscript.com/pt-pt/produtos/api-modelos-customizados/';
    case 'es':
      return 'https://www.amberscript.com/es/productos/api-modelos-personalizados/';
    case 'it':
      return 'https://www.amberscript.com/it/prodotti/api-modelli-personalizzati/';
    default:
      return 'https://helpdesk.amberscript.com/en/integrations/';
  }
}

/**
 * Return the right Upload explanation page
 * @param {string} lang
 * @returns link in the correct language
 */
export function getUploadExplanationByLang(lang) {
  switch (lang) {
    case 'en':
      return 'https://www.amberscript.com/en/upload-explanation/';
    case 'fr':
      return 'https://www.amberscript.com/fr/explication-du-telechargement/';
    case 'de':
    case 'nl':
    case 'pt':
    case 'es':
    case 'it':
    default:
      return 'https://www.amberscript.com/en/upload-explanation/';
  }
}
