import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberTitle(props) {
  const { text, testid } = props;

  return (
    <Box
      component="span"
      color="custom.charcoalGray"
      whiteSpace="nowrap"
      marginTop={1}
      marginBottom={1}
    >
      <Typography variant="header" data-testid={testid}>
        {text}
      </Typography>
    </Box>
  );
}

AmberTitle.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
};

AmberTitle.defaultProps = {
  testid: '',
};
