/* eslint-disable import/prefer-default-export */

/**
 * Returns true if the browser is Safari
 */
export function isBrowserSafari() {
  return (
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  );
}
