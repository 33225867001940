import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberTextError(props) {
  const { text, textAlign = 'left', testid = '' } = props;

  return (
    <Box
      data-testid={testid}
      component="span"
      marginTop={1}
      marginBottom={1}
      textAlign={textAlign}
      color="error.main"
    >
      <Typography variant="normal">{text}</Typography>
    </Box>
  );
}

AmberTextError.propTypes = {
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  testid: PropTypes.string,
};

AmberTextError.defaultProps = {
  textAlign: 'left',
  testid: '',
};
