import React, { useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  getProviderRedirectResult,
  setupFirebaseAuthentication,
  signInWithRedirectToProvider,
} from '../../../helpers/firebaseAuthentication';
import LoadingPageWhileGettingUserData from '../../LoadingPage';
import idpConfigMap from '../../../samlConfig';
import { showNotification } from '../Uploads/newUploadForm/helpers/UI';
import { AMBER_NOTIFICATION_ERROR } from '../../../helpers/constants';
import NotFound from '../../NotFound';

const SAMLLoginRoute = () => {
  const { subdomain } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const idpConfig = idpConfigMap[subdomain];

  useEffect(() => {
    if (!idpConfig) {
      navigate('/not-found');
      return;
    }

    const initiateSAMLLogin = async () => {
      try {
        await setupFirebaseAuthentication();
        const result = await getProviderRedirectResult(
          searchParams.get('email'),
        );

        if (result !== null) {
          navigate('/');
        } else {
          signInWithRedirectToProvider(idpConfig.externalProviderId);
        }
      } catch (error) {
        showNotification(
          AMBER_NOTIFICATION_ERROR,
          'An error occurred during SAML login',
        );
      }
    };

    initiateSAMLLogin();
  }, [idpConfig, searchParams, navigate]);

  return idpConfig ? <LoadingPageWhileGettingUserData /> : <NotFound />;
};

export default SAMLLoginRoute;
