import { atom } from 'recoil';
import { REFERRAL_DIALOG_ATOM } from './constants';

// Atoms
// eslint-disable-next-line import/prefer-default-export
export const viewReferralDialogAtom = atom({
  key: REFERRAL_DIALOG_ATOM,
  default: {
    open: false,
  },
});
