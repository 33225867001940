import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AmberCircularLoading from './Common/ProgressBars/AmberCircularLoading';
import { ReactComponent as AmberBlackIcon } from '../assets/images/AmberLogoBlack.svg';
import AmberText from './Common/Typography/AmberText';

export default function LoadingPageWhileGettingUserData() {
  const [t] = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        position: 'absolute',
        zIndex: 10001,
      }}
      bgcolor="white"
    >
      <Grid item xs={3}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          data-testid="circular-loading"
        >
          <Box display="flex" position="absolute" maxWidth={60}>
            <AmberBlackIcon style={{ width: 60, height: 60 }} />
          </Box>
          <Box position="absolute" marginTop={2.4}>
            <AmberCircularLoading size={120} />
          </Box>
          <Box display="flex" marginTop={60}>
            <AmberText text={t('CHECKOUT.pleaseWait')} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
