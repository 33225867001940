import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberTextBold(props) {
  const {
    text,
    textAlign = 'left',
    noWrap = false,
    color = 'unset',
    testid = '',
    lineHeight = '1.5rem',
  } = props;

  return (
    <Box
      data-testid={testid}
      component="span"
      marginTop={1}
      marginBottom={1}
      textAlign={textAlign}
      whiteSpace={noWrap ? 'nowrap' : 'normal'}
      color={color || 'unset'}
      lineHeight={lineHeight}
    >
      <Typography variant="normalBold">{text}</Typography>
    </Box>
  );
}

AmberTextBold.propTypes = {
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  color: PropTypes.string,
  noWrap: PropTypes.bool,
  testid: PropTypes.string,
  lineHeight: PropTypes.string,
};

AmberTextBold.defaultProps = {
  textAlign: 'left',
  noWrap: false,
  color: 'unset',
  testid: '',
  lineHeight: '1,5rem',
};
