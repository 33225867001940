import { ANALYTICS_ID, ANALYTICS_CLIENT_ID } from './constants';

/**
 * Get ANALYTICS_ID
 * @return {string} Return refreshToken stored in the local storage
 */
export function getAnalyticsID() {
  return localStorage.getItem(ANALYTICS_ID);
}

/**
 * Get ANALYTICS_CLIENT_ID
 * @return {string} Return refreshToken stored in the local storage
 */
export function getAnalyticsClientID() {
  return localStorage.getItem(ANALYTICS_CLIENT_ID);
}

/**
 * Remove token from the local storage
 */
export function removeToken(token) {
  return localStorage.removeItem(token);
}

/**
 * Store {key, value} pair into the local storage
 * @param {string} key
 * @param {string} value
 */
export function storeItemInLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

/**
 * Reads the value of the {key, value} pair from local storage
 * @param {string} key
 * @returns {string} value
 */
export function getItemFromLocalStorage(key) {
  return localStorage.getItem(key);
}
