import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import AmberRootButton from './AmberRootButton';

const CustomButton = styled(Button)(
  ({ theme }) => `
    height: 25px;
    background-color: ${theme.palette.primary.main};
    color: white;

    span {
        font-size: 12px;
        font-weight: 500;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
    }
`,
);

export default function AmberListButton(props) {
  const { id, text, disabled, handleClick, testid } = props;

  return (
    <CustomButton
      id={id || undefined}
      data-testid={testid}
      variant="contained"
      disabled={disabled}
      onClick={handleClick}
      disableElevation
    >
      <AmberRootButton>
        <Typography variant="button">{text}</Typography>
      </AmberRootButton>
    </CustomButton>
  );
}

AmberListButton.propTypes = {
  id: PropTypes.string,
  handleClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

AmberListButton.defaultProps = {
  id: '',
  disabled: false,
  testid: '',
  handleClick: undefined,
};
