import axios from 'axios';
import handlePostLogout from '../helpers/logout';
import {
  cleanUpNonUserToken,
  getNonUserToken,
} from '../helpers/uploadBeforeSignup';

const baseURL = `${process.env.REACT_APP_BE_BASE_URL}/v2/`;

// Var(s)
let isRefreshingAccessToken = false;
let requestsToRefresh = [];

const apiClient = axios.create({
  withCredentials: true,
  baseURL,
});

apiClient.defaults.headers.common['X-Frame-Options'] = 'DENY';
apiClient.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';
/**
 * Returns true if the nonUserToken is defined
 * @returns {boolean} nonUserToken
 */
function shouldRedirectToLogin() {
  cleanUpNonUserToken();
  return !!getNonUserToken();
}

/**
 * Redirects to login page if we are not in the upload before signup flow
 */
function handleLogout() {
  const isUploadBeforeSignup = shouldRedirectToLogin();
  if (!isUploadBeforeSignup) {
    handlePostLogout();
    window.location.replace('/login');
  }
}

function refreshAccessToken() {
  return apiClient.post(
    `${baseURL}refresh-token`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
}

// Before hook
apiClient.interceptors.request.use((config) => {
  isRefreshingAccessToken = false;
  requestsToRefresh = [];

  const newConfig = config;

  return newConfig;
});

// After hook
apiClient.interceptors.response.use(null, (error) => {
  try {
    const { response, config } = error;

    // Unauthorized user
    if (
      response &&
      response.status === 401 &&
      response.data === 'Invalid token'
    ) {
      // Check if we are in the middle of refreshing the token
      if (!isRefreshingAccessToken) {
        isRefreshingAccessToken = true;

        refreshAccessToken()
          .then((resRefreshToken) => {
            requestsToRefresh.forEach((cb) =>
              cb(resRefreshToken.data.accessToken),
            );
          })
          .catch(() => {
            handleLogout();
          });
      }
      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token) => {
          if (token) {
            resolve(axios(config));
          } else {
            reject(error);
          }
        });
      });
    }

    return Promise.reject(error);
  } catch (e) {
    return Promise.reject(e);
  }
});

export default apiClient;
