import {
  SET_SYSTEM_BUSY,
  SET_SYSTEM_FREE,
  UPDATE_LOADING_BAR,
  UPDATE_NOTIFICATION,
} from '../states/constants/generic';

export function showLoadingBar(dispatch, message) {
  dispatch({
    type: UPDATE_LOADING_BAR,
    data: {
      open: true,
      message: message,
    },
  });
}

export function hideLoadingBar(dispatch) {
  setTimeout(() => {
    dispatch({
      type: UPDATE_LOADING_BAR,
      data: {
        open: false,
      },
    });
  }, 500);
}

export function showNotification(dispatch, variant, message) {
  dispatch({
    type: UPDATE_NOTIFICATION,
    data: {
      open: true,
      variant,
      message: message,
    },
  });
  setTimeout(() => {
    hideNotification(dispatch);
  }, 5000);
}

export function hideNotification(dispatch) {
  dispatch({
    type: UPDATE_NOTIFICATION,
    data: {
      open: false,
    },
  });
}

export function setSystemToBusy(dispatch) {
  dispatch({
    type: SET_SYSTEM_BUSY,
  });
}

export function setSystemToFree(dispatch) {
  setTimeout(() => {
    dispatch({
      type: SET_SYSTEM_FREE,
    });
  }, 100);
}
