import React from 'react';
import { useRecoilValue } from 'recoil';

import ReferralDialogView from './referralDialog';
import { viewReferralDialogAtom } from '../../../store/referral';

export default function ReferralDialog() {
  const viewReferralDialog = useRecoilValue(viewReferralDialogAtom);

  if (!viewReferralDialog.open) return <></>;

  return <ReferralDialogView />;
}
