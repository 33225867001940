import apiClient from './apiClient';

/**
 * Get user's glossaries
 * @returns Promise
 */
export function getGlossaries() {
  return apiClient.get('glossary');
}

/**
 * Create a glossary
 * @param {object} glossary
 * @returns Promise
 */
export function createGlossary(glossary) {
  const { name, names, items } = glossary;
  const data = {
    name,
    names,
    items,
  };
  return apiClient.post('glossary', data);
}

/**
 * Delete a glossary by id
 * @param {string} id
 * @returns Promise
 */
export function deleteGlossary(id) {
  return apiClient.delete(`glossary/${id}`);
}

/**
 * Update a glossary by id
 * @param {object} glossary
 * @returns Promise
 */
export function updateGlossary(glossary) {
  const { id, name, names, items } = glossary;
  const data = {
    name,
    names,
    items,
  };
  return apiClient.put(`glossary/${id}`, data);
}
